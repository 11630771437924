import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const rolesAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.ROLES, data);
  return response;
};

export const rolesEdit = async (id, data) => {
  const response = await httpServiceInstance.put(
    ApiUrl.ROLES + "?id=" + id,
    data
  );
  return response;
};

export const rolesRemove = async (id) => {
  const response = await httpServiceInstance.delete(ApiUrl.ROLES + "?id=" + id);
  return response;
};

export const roles = async () => {
  const url = `${ApiUrl.ROLES}?${Constants.paginationFalse}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const roleUsersAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.ROLE_USERS, data);
  return response;
};

export const roleUsersEdit = async (id, data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.ROLE_USERS + "?id=" + id,
    data
  );
  return response;
};

export const roleUsersRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.ROLE_USERS + "/" + id
  );
  return response;
};

export const roleUsers = async (data) => {
  const url = `${ApiUrl.ROLE_USERS}?type=${Constants.roleKey}${
    data?.skipPagination
      ? `&${Constants.paginationFalse}`
      : `&skip=${data?.skip || 0}&limit=${data?.limit || Constants.limitCount}`
  }${data && data?.search ? `&search=${data?.search}` : ""}${
    data?.status ? `&status=${data?.status}` : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const rolePermissionsAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PERMISSION, data);
  return response;
};

export const rolePermissions = async (id) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.PERMISSION}?role=${id}`
  );
  return response;
};
