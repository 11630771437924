import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const primaryMuscleAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PRIMARY_MUSCLE, data);
  return response;
};

export const primaryMuscleEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.PRIMARY_MUSCLE, data);
  return response;
};

export const primaryMuscleRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.PRIMARY_MUSCLE + `/${id}`
  );
  return response;
};

export const primaryMuscle = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.PRIMARY_MUSCLE}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}${
      data?.status ? `&status=${data?.status}` : ""
    }`
  );
  return response;
};
