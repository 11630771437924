import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import {
  programmeInsert,
  programmeList,
  programmeUpdate,
} from "../../Redux/Actions";
import ReactSelect from "../Common/Fields/ReactSelect";
import SingleSelect from "../Exercise/SingleSelect";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import TextArea from "../Common/Fields/TextArea";
import usePermissions from "../Common/Functions/usePermissions";

const ProgrammeForm = () => {
  const dispatch = useDispatch();
  const { programmeSingle } = useSelector((state) => state.Programme);
  const { bodygrouplist } = useSelector((state) => state.BodyGroup);
  const { goalslist } = useSelector((state) => state.Goals);
  const [selectedValues, setSelectedValues] = useState({
    experience: Constants.levelOptions?.[0],
    goal: [],
    bodyGroup: [],
  });
  // Permissions
  const addPermission = usePermissions(Constants.permission.PROGRAMME_ADD);
  const editPermission = usePermissions(Constants.permission.PROGRAMME_EDIT);

  // Initial values to Workout form
  const [initialValues, setInitialValues] = useState({
    programId: "",
    programName: "",
    description: "",
    tenure: "",
    experience: Constants?.levelOptions?.[0]?.value,
    goal: "",
    bodyGroup: "",
    thumbnail: "",
  });

  // Validations for Workout form
  const validationSchema = Yup.object().shape({
    programId: Yup.string().required(Constants.required),
    programName: Yup.string().required(Constants.required),
    description: Yup.string().required(Constants.required),
    tenure: Yup.number().required(Constants.required),
    experience: Yup.string().required(Constants.required),
    goal: Yup.string().required(Constants.required),
    bodyGroup: Yup.string().required(Constants.required),
    thumbnail: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill Workout form
    if (programmeSingle?.items?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (programmeSingle?.items[key]) {
          editValue[key] = programmeSingle?.items[key];
          if (key === "goal") editValue.goal = programmeSingle?.items[key]?._id;
          if (key === "bodyGroup")
            editValue.bodyGroup = programmeSingle?.items[key]?._id;
          const selectedVal = preFilledValues(programmeSingle?.items);
          setSelectedValues(selectedVal);
        }
        setInitialValues(editValue);
      }
    }
  }, [programmeSingle?.items]);

  const getValueLabelObject = (data) => {
    if (!data) return "";
    return {
      value: data?._id,
      label: data?.name || data?.title,
    };
  };

  const preFilledValues = (programmeSingle) => {
    if (!programmeSingle) return {};
    return {
      experience: Constants.levelOptions.find(
        (elt) => elt.value === programmeSingle.experience
      ),
      goal: getValueLabelObject(programmeSingle.goal, true),
      bodyGroup: getValueLabelObject(programmeSingle.bodyGroup),
    };
  };

  const onSingleSelectChange = (e, key, setKey) => {
    setSelectedValues((prev) => ({ ...prev, [key]: e }));
    setKey(key, e.value);
  };

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  thumbnail for programme
    setFieldValue(field, file?.[0]);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update Workout API
        let submitFunc = programmeInsert(values);
        if (programmeSingle?.items?._id) {
          values.id = programmeSingle?.items?._id;
          submitFunc = programmeUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.PROGRAMME_SINGLE, payload: "" });
            dispatch({ type: types.PROGRAMME_FORM_MODEL, payload: "" });
          }
          dispatch(programmeList());
        });
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="programId"
              className="form-control"
              errors={touched?.programId ? errors?.programId : undefined}
              touched={touched?.programId}
              label={Constants.id}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="programName"
              className="form-control"
              errors={touched?.programName ? errors?.programName : undefined}
              touched={touched?.programName}
              label={Constants.name}
            />
          </div>
          <div className="col-md-12 mb-2">
            <TextArea
              type="text"
              name="description"
              className="form-control"
              errors={touched?.description ? errors?.description : undefined}
              touched={touched?.description}
              label={Constants.description}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="number"
              name="tenure"
              className="form-control"
              errors={touched?.tenure ? errors?.tenure : undefined}
              touched={touched?.tenure}
              label={Constants.tenure}
            />
          </div>
          <div className="col-md-6 mb-2">
            <ReactSelect
              label={`${Constants.experience}`}
              required={true}
              options={Constants.levelOptions}
              onChange={(e) =>
                onSingleSelectChange(e, "experience", setFieldValue)
              }
              value={selectedValues?.experience}
            />
          </div>
          <div className="col-md-6 mb-2">
            <SingleSelect
              label={`${Constants.bodyGroup}`}
              required={true}
              options={bodygrouplist?.items || []}
              onChange={(e) =>
                onSingleSelectChange(e, "bodyGroup", setFieldValue)
              }
              value={selectedValues.bodyGroup}
              errors={touched?.bodyGroup ? errors?.bodyGroup : undefined}
              touched={touched?.bodyGroup}
            />
          </div>
          <div className="col-md-6 mb-2">
            <SingleSelect
              label={`${Constants.goals}`}
              required={true}
              options={goalslist?.goals || []}
              onChange={(e) => onSingleSelectChange(e, "goal", setFieldValue)}
              value={selectedValues.goal}
              errors={touched?.goal ? errors?.goal : undefined}
              touched={touched?.goal}
            />
          </div>
          <div className="col-md-6 my-2">
            <label className="form-label">{`${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.thumbnail && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="381"
                    height="426"
                    errors={touched?.thumbnail ? errors?.thumbnail : undefined}
                    touched={touched?.thumbnail}
                    label={`${Constants.upload} ${Constants.thumbnail}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "thumbnail")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.thumbnail && (
                  <ViewImageFile
                    file={values?.thumbnail}
                    onClick={() => setFieldValue("thumbnail", "")}
                  />
                )}
              </div>
            </div>
          </div>
          {((addPermission && !programmeSingle?.items?._id) ||
            (editPermission && programmeSingle?.items?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ProgrammeForm;
