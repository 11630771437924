import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../../Common/Constants";
import { types } from "../../../Redux/Actions/types";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import {
  synergistMuscleInsert,
  synergistMuscleList,
  synergistMuscleUpdate,
} from "../../../Redux/Actions";
import usePermissions from "../../Common/Functions/usePermissions";

const SynergistMuscleForm = () => {
  const dispatch = useDispatch();
  const { synergistMuscleSingle } = useSelector(
    (state) => state.SynergistMuscle
  );
  // Permissions
  const addPermission = usePermissions(Constants.permission.BODY_PART_ADD);
  const editPermission = usePermissions(Constants.permission.BODY_PART_EDIT);

  // Initial values to synergist muscle form
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  // Validations for synergist muscle form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill synergist muscle form
    if (synergistMuscleSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (synergistMuscleSingle[key]) {
          editValue[key] = synergistMuscleSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [synergistMuscleSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update synergist muscle API
        let submitFunc = synergistMuscleInsert(values);
        if (synergistMuscleSingle._id) {
          values.id = synergistMuscleSingle._id;
          submitFunc = synergistMuscleUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.SYNERGIST_MUSCLE_SINGLE, payload: "" });
            dispatch({ type: types.SYNERGIST_MUSCLE_FORM_MODEL, payload: "" });
          }
          dispatch(synergistMuscleList());
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          {((addPermission && !synergistMuscleSingle?._id) ||
            (editPermission && synergistMuscleSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SynergistMuscleForm;
