import { getDashboardData } from "../../services/dashboard.service";
import { types } from "./types";

export const fetchDashboardData = (days) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await getDashboardData(days);
  if (response?.status) {
    const result = response?.data?.response;
    dispatch({ type: types.DASHBOARD_DATA, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.DASHBOARD_DATA,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
