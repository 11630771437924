import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../../Common/Constants";
import { types } from "../../../Redux/Actions/types";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import {
  mechanicsJointInsert,
  mechanicsJointList,
  mechanicsJointUpdate,
} from "../../../Redux/Actions";
import usePermissions from "../../Common/Functions/usePermissions";

const MechanicsJointForm = () => {
  const dispatch = useDispatch();
  const { mechanicsJointSingle } = useSelector((state) => state.MechanicsJoint);
  // Permissions
  const addPermission = usePermissions(Constants.permission.MECHANICS_ADD);
  const editPermission = usePermissions(Constants.permission.MECHANICS_EDIT);

  // Initial values to mechanics joint form
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  // Validations for mechanics joint form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill mechanics joint form
    if (mechanicsJointSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (mechanicsJointSingle[key]) {
          editValue[key] = mechanicsJointSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [mechanicsJointSingle]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update mechanics joint API
        let submitFunc = mechanicsJointInsert(values);
        if (mechanicsJointSingle._id) {
          values.id = mechanicsJointSingle._id;
          submitFunc = mechanicsJointUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.MECHANICS_JOINT_SINGLE, payload: "" });
            dispatch({ type: types.MECHANICS_JOINT_FORM_MODEL, payload: "" });
          }
          dispatch(mechanicsJointList());
        });
      }}
    >
      {({ errors, touched }) => (
        <Form className="row">
          <div className="col-md-12 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          {((addPermission && !mechanicsJointSingle?._id) ||
            (editPermission && mechanicsJointSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MechanicsJointForm;
