import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const challengeAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.CHALLENGE, data);
  return response;
};

export const challengeEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.CHALLENGE, data);
  return response;
};

export const challengeRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.CHALLENGE + `/${id}`
  );
  return response;
};

export const challenge = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.CHALLENGE}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}${
      data?.status ? `&status=${data?.status}` : ""
    }`
  );
  return response;
};

export const detailChallenge = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.CHALLENGE + `/${id}`);
  return response;
};

export const usersChallenge = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.CHALLENGE_USER +
      `?challengeId=${data?.id}${
        data?.skipPagination
          ? `&${Constants.paginationFalse}`
          : `&skip=${data?.skip || 0}&limit=${
              data?.limit || Constants.limitCount
            }`
      }`
  );
  return response;
};
