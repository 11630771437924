import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Constants } from "../Common/Constants";
import { getAdminConfigurations } from "../../Redux/Actions";

const section = { type: Constants.cmsDashboardKey };

// Static dashboard for t hose users who does not have dashboad view access
const StaticDashboard = () => {
  const [settings, setSettings] = useState([]);
  const dispatch = useDispatch();

  // to get the image added by admin in configuration for static dashboard
  useEffect(() => {
    dispatch(getAdminConfigurations(section)).then((response) => {
      setSettings(response?.data?.data?.items);
    });
  }, []);

  return (
    <div>
      <div className="container-xxl mt-3">
        <div className="card-h">
          <img
            src={settings?.description}
            alt="welcome"
            className="img-fluid w-50  mx-auto my-4"
          />
        </div>
      </div>
    </div>
  );
};

export default StaticDashboard;
