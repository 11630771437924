import { ApiUrl } from "../../Common/ApiUrl";
import { Constants } from "../../Common/Constants";
const { permissionApiRoute } = Constants;

const bodyPartsApis = [
  `${permissionApiRoute}${ApiUrl.BODY_GROUP}`,
  `${permissionApiRoute}${ApiUrl.EXERCISE_POSITION}`,
  `${permissionApiRoute}${ApiUrl.JOINT}`,
  `${permissionApiRoute}${ApiUrl.MUSCLE}`,
  `${permissionApiRoute}${ApiUrl.PRIMARY_JOINT}`,
  `${permissionApiRoute}${ApiUrl.PRIMARY_MUSCLE}`,
  `${permissionApiRoute}${ApiUrl.SYNERGIST_MUSCLE}`,
];
const cmsApis = [
  `${permissionApiRoute}${ApiUrl.FAQ}`,
  `${permissionApiRoute}${ApiUrl.GOAL_TIMING}`,
  `${permissionApiRoute}${ApiUrl.CONFIGURATION}`,
];
const mechanicsApis = [
  `${permissionApiRoute}${ApiUrl.MECHANICS_JOINT}`,
  `${permissionApiRoute}${ApiUrl.MOVEMENT_PATTERN}`,
  `${permissionApiRoute}${ApiUrl.FORCE}`,
];
const workoutApis = [
  `${permissionApiRoute}${ApiUrl.WORKOUT}`,
  `${permissionApiRoute}${ApiUrl.WORKOUT_EXERCISE}`,
];

// All API routes for main tabs and inside tabs
export const ApiRoutes = {
  dashboardView: [`${permissionApiRoute}${ApiUrl.DASHBOARD}`],
  bodyPartsView: bodyPartsApis,
  bodyPartsEdit: bodyPartsApis,
  bodyPartsAdd: bodyPartsApis,
  bodyPartsDelete: bodyPartsApis,
  badgesView: [
    `${permissionApiRoute}${ApiUrl.BADGE}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.BADGE_USER}`,
  ],
  badgesEdit: [`${permissionApiRoute}${ApiUrl.BADGE}`],
  badgesAdd: [`${permissionApiRoute}${ApiUrl.BADGE}`],
  badgesDelete: [`${permissionApiRoute}${ApiUrl.BADGE}`],
  challengeView: [
    `${permissionApiRoute}${ApiUrl.CHALLENGE}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.CHALLENGE_USER}`,
  ],
  challengeEdit: [`${permissionApiRoute}${ApiUrl.CHALLENGE}`],
  challengeAdd: [`${permissionApiRoute}${ApiUrl.CHALLENGE}`],
  challengeDelete: [`${permissionApiRoute}${ApiUrl.CHALLENGE}`],
  usersView: [
    `${permissionApiRoute}${ApiUrl.USER_LIST}`,
    `${permissionApiRoute}${ApiUrl.USER_DETAIL}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.STRENGTH_ASSESS_EXERCISE}`,
    `${permissionApiRoute}${ApiUrl.USER_CUSTOM_WORKOUT}`,
  ],
  cmsView: cmsApis,
  cmsEdit: cmsApis,
  cmsAdd: cmsApis,
  cmsDelete: [`${permissionApiRoute}${ApiUrl.FAQ}`],
  equipmentView: [`${permissionApiRoute}${ApiUrl.EQUIPMENT}`],
  equipmentEdit: [`${permissionApiRoute}${ApiUrl.EQUIPMENT}`],
  equipmentAdd: [`${permissionApiRoute}${ApiUrl.EQUIPMENT}`],
  equipmentDelete: [`${permissionApiRoute}${ApiUrl.EQUIPMENT}`],
  exerView: [
    `${permissionApiRoute}${ApiUrl.EXERCISE}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_GOAL}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_POSITION}`,
    `${permissionApiRoute}${ApiUrl.MECHANICS_JOINT}`,
    `${permissionApiRoute}${ApiUrl.MOVEMENT_PATTERN}`,
    `${permissionApiRoute}${ApiUrl.FORCE}`,
    `${permissionApiRoute}${ApiUrl.JOINT}`,
    `${permissionApiRoute}${ApiUrl.MUSCLE}`,
    `${permissionApiRoute}${ApiUrl.EQUIPMENT}`,
    `${permissionApiRoute}${ApiUrl.BODY_GROUP}`,
    `${permissionApiRoute}${ApiUrl.PRIMARY_JOINT}`,
    `${permissionApiRoute}${ApiUrl.PRIMARY_MUSCLE}`,
    `${permissionApiRoute}${ApiUrl.SYNERGIST_MUSCLE}`,
  ],
  exerEdit: [`${permissionApiRoute}${ApiUrl.EXERCISE}`],
  exerAdd: [`${permissionApiRoute}${ApiUrl.EXERCISE}`],
  exerDelete: [`${permissionApiRoute}${ApiUrl.EXERCISE}`],
  exerCategoryView: [`${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`],
  exerCategoryEdit: [`${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`],
  exerCategoryAdd: [`${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`],
  exerCategoryDelete: [`${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`],
  exerGoalView: [`${permissionApiRoute}${ApiUrl.EXERCISE_GOAL}`],
  exerGoalEdit: [`${permissionApiRoute}${ApiUrl.EXERCISE_GOAL}`],
  exerGoalAdd: [`${permissionApiRoute}${ApiUrl.EXERCISE_GOAL}`],
  exerGoalDelete: [`${permissionApiRoute}${ApiUrl.EXERCISE_GOAL}`],
  goalView: [`${permissionApiRoute}${ApiUrl.GOAL}`],
  goalEdit: [`${permissionApiRoute}${ApiUrl.GOAL}`],
  goalAdd: [`${permissionApiRoute}${ApiUrl.GOAL}`],
  goalDelete: [`${permissionApiRoute}${ApiUrl.GOAL}`],
  mechanicsView: mechanicsApis,
  mechanicsEdit: mechanicsApis,
  mechanicsAdd: mechanicsApis,
  mechanicsDelete: mechanicsApis,
  notifiView: [`${permissionApiRoute}${ApiUrl.NOTIFICATION}`],
  notifiAdd: [`${permissionApiRoute}${ApiUrl.NOTIFICATION}`],
  programmeView: [
    `${permissionApiRoute}${ApiUrl.PROGRAMME}`,
    `${permissionApiRoute}${ApiUrl.GOAL}`,
    `${permissionApiRoute}${ApiUrl.BODY_GROUP}`,
    `${permissionApiRoute}${ApiUrl.PROGRAMME_USER}`,
    `${permissionApiRoute}${ApiUrl.WORKOUT}`,
  ],
  programmeEdit: [`${permissionApiRoute}${ApiUrl.PROGRAMME}`],
  programmeAdd: [
    `${permissionApiRoute}${ApiUrl.PROGRAMME}`,
    `${permissionApiRoute}${ApiUrl.DAY_WORKOUT}`,
  ],
  programmeDelete: [
    `${permissionApiRoute}${ApiUrl.PROGRAMME}`,
    `${permissionApiRoute}${ApiUrl.DAY_WORKOUT}`,
  ],
  reportView: [
    `${permissionApiRoute}${ApiUrl.FREQUENT_USERS}`,
    `${permissionApiRoute}${ApiUrl.WORKOUT_ANALYTICS}`,
    `${permissionApiRoute}${ApiUrl.STRENGTH_ASSESS_EXERCISE}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`,
  ],
  reportDownloadView: [
    `${permissionApiRoute}${ApiUrl.WORKOUT_REPORT_EXCEL}`,
    `${permissionApiRoute}${ApiUrl.STRENGTH_ASSESS_EXERCISE}`,
    `${permissionApiRoute}${ApiUrl.EXERCISE_CATEGORY}`,
  ],
  roleMgtView: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
    `${permissionApiRoute}${ApiUrl.PERMISSION}`,
  ],
  roleMgtEdit: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
  ],
  roleMgtAdd: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
    `${permissionApiRoute}${ApiUrl.PERMISSION}`,
  ],
  roleMgtDelete: [
    `${permissionApiRoute}${ApiUrl.USERS}`,
    `${permissionApiRoute}${ApiUrl.ROLES}`,
  ],
  tutorialView: [`${permissionApiRoute}${ApiUrl.TUTORIAL}`],
  tutorialEdit: [`${permissionApiRoute}${ApiUrl.TUTORIAL}`],
  tutorialAdd: [`${permissionApiRoute}${ApiUrl.TUTORIAL}`],
  tutorialDelete: [`${permissionApiRoute}${ApiUrl.TUTORIAL}`],
  workoutView: [
    `${permissionApiRoute}${ApiUrl.GOAL}`,
    `${permissionApiRoute}${ApiUrl.WORKOUT}`,
    `${permissionApiRoute}${ApiUrl.PRIMARY_MUSCLE}`,
    `${permissionApiRoute}${ApiUrl.FORCE}`,
    `${permissionApiRoute}${ApiUrl.EQUIPMENT}`,
  ],
  workoutEdit: workoutApis,
  workoutAdd: workoutApis,
  workoutDelete: workoutApis,
};
