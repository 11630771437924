/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "../../Redux/Actions/Users";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import dayjs from "dayjs";
import titleCase from "../Common/Functions/titleCase";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import ReactSelect from "../Common/Fields/ReactSelect";
import DateRangePicker from "../Common/Fields/DateRangePicker";
import { Button } from "react-bootstrap";
import usePermissions from "../Common/Functions/usePermissions";

const initDate = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

const UsersList = () => {
  const dispatch = useDispatch();
  const { userslist } = useSelector((state) => state.Users);
  const [usersData, setUsersData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedGender, setSelectedGender] = useState(
    Constants.genderOptions[0]
  );
  const [range, setRange] = useState({});
  const [showCalender, setShowCalender] = useState(false);
  const [initialDate, setInitialDate] = useState(initDate);
  const calendarRef = useRef(null);
  const viewPermission = usePermissions(Constants.permission.CUSTOMER_VIEW);

  useEffect(() => {
    // Users list API
    getUsersData();
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Set user list data
    setUsersData(userslist.user);
  }, [userslist]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // De-bounce function for search with delay of 500 seconds
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getUsersData(search);
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [search]);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalender(false);
    }
  };

  // Columns for User list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.email,
      selector: (row) => (
        <Link to={viewPermission ? `${RoutesUrl.users}/${row?._id}` : ""}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.email}
          >
            {row?.email}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.name,
      selector: (row) => row?.userName || Constants.na,
    },
    {
      name: Constants.gender,
      selector: (row) => titleCase(row?.gender),
    },
    {
      name: Constants.dob,
      selector: (row) =>
        row?.dob ? dayjs(row?.dob).format(Constants.dateFormat) : Constants.na,
    },
    {
      name: Constants.height,
      selector: (row) =>
        row?.heightType === Constants.heightTypeFt
          ? `${row.feet} ft ${row.inchs} in`
          : `${row.centimeters} cms`,
    },
    {
      name: Constants.weight,
      selector: (row) =>
        `${row.weight} ${
          row.weightType === Constants.weightTypeLbs ? "lbs" : "kgs"
        }`,
    },
  ];

  const getUsersData = (searchKey) => {
    // User list API based on all filters
    const data = {
      search: searchKey,
      gender: selectedGender.value,
      start_date: range?.start_date,
      end_date: range?.end_date,
    };
    dispatch(usersList(data));
  };

  const handleGender = (selected) => {
    setSelectedGender(selected);
    setPageIndex(1);
    const data = {
      gender: selected.value,
      search,
      start_date: range?.start_date,
      end_date: range?.end_date,
    };
    dispatch(usersList(data));
  };

  const onResetFilter = () => {
    setSelectedGender(Constants.genderOptions?.[0]);
    setRange({});
    setInitialDate(initDate);
    setSearch("");
    setPageIndex(1);
    dispatch(usersList());
  };

  // Pagination fuction
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
      gender: selectedGender.value,
      start_date: range?.start_date,
      end_date: range?.end_date,
    };
    dispatch(usersList(data));
  };

  const onRangeChange = (range) => {
    setInitialDate({
      startDate: range?.[0]?.startDate,
      endDate: range?.[0]?.endDate,
      key: "selection",
    });
    setRange({
      start_date: dayjs(range?.[0]?.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(range?.[0]?.endDate).format("YYYY-MM-DD"),
    });
  };

  // date range filter
  const onDateFilter = () => {
    setShowCalender(false);
    const data = {
      skip: 0,
      limit: Constants.limitCount,
      search,
      gender: selectedGender.value,
      start_date: range?.start_date,
      end_date: range?.end_date,
    };
    dispatch(usersList(data));
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader pagetitle={Constants.customerList} />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div className="col-md-5">
          <div className="search-w pull-right space-calender2 position-relative">
            <div
              className={`calender-outer pos_inherit ${
                range?.start_date ? "calender-active" : ""
              }`}
            >
              <i
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                }}
                className="fa fa-calendar"
                aria-hidden="true"
                onClick={() => {
                  setShowCalender((prevState) => !prevState);
                }}
              ></i>
            </div>
            <div className="row">
              {showCalender && (
                <div ref={calendarRef} className="date-pos">
                  <div>
                    <DateRangePicker
                      initialDate={initialDate}
                      rangeSelected={(range) => {
                        onRangeChange(range);
                      }}
                    />
                  </div>
                  <div className="appy-btn">
                    <Button
                      className="text-uppercase fw-bold text-center mx-auto d-block"
                      onClick={() => {
                        onDateFilter();
                      }}
                    >
                      {Constants.apply}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <ReactSelect
            label={`${Constants.gender}`}
            required={false}
            options={Constants.genderOptions}
            onChange={(selected) => handleGender(selected)}
            value={selectedGender}
          />
        </div>
        <div className="col-md-2">
          <label className="form-label">{Constants.search}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByEmail}
            onChange={(event) => {
              setSearch(event.target.value);
              setPageIndex(1);
              if (event.target.value === "") {
                setSearch("");
                getUsersData("");
              }
            }}
          ></input>
        </div>
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={usersData}
        defaultSortFieldId
        highlightOnHover
      />
      {userslist?.totalCount > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={userslist?.totalCount}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
          pageIndex={pageIndex - 1}
        />
      )}
    </div>
  );
};

export default UsersList;
