import React from "react";
import { useSelector } from "react-redux";
import AreaGraphMonthly from "../../Common/Graphs/AreaGraphMonthyl";
import { Constants } from "../../Common/Constants";

const Monthly = () => {
  const { workoutanalytics } = useSelector((store) => store.Reports);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.totalWorkoutSession}
          title={Constants.totalWorkouts}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.totalReps}
          title={Constants.totalReps}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.totalWeighLifted}
          title={Constants.totalWeightLifted}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.avgworkoutsession}
          title={Constants.totalWorkoutSessionDuration}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.totalcaloriesBurnt}
          title={Constants.caloriesBurnt}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.totaltimeUnderTension}
          title={Constants.timeUnderTension}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.userStrengthAssessment}
          title={Constants.strengthAssessment}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.userGoalGetLeaner}
          title={Constants.getLeaner}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.userGoalGetStronger}
          title={Constants.getStronger}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Monthly;
