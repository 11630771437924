import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { challengeUsers } from "../../../Redux/Actions";
import { Constants } from "../../Common/Constants";
import { RoutesUrl } from "../../Common/RoutesUrl";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import ReactPagination from "../../Common/Fields/ReactPagination";
import usePermissions from "../../Common/Functions/usePermissions";

const ProgrammeResponsePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { programmeuserlist } = useSelector((state) => state.Programme);
  const [programmeUserData, setProgrammeUserData] = useState([]);
  const viewPermission = usePermissions(Constants.permission.CUSTOMER_VIEW);

  useEffect(() => {
    // Set programme users data
    setProgrammeUserData(programmeuserlist?.items);
  }, [programmeuserlist]);

  // Columns for Response Table List
  const columns = [
    {
      name: Constants.name,
      cell: (row) => (
        <>
          {viewPermission ? (
            <Link to={`${RoutesUrl.users}/${row?._id}`}>
              {row?.userId?.name || Constants.na}
            </Link>
          ) : (
            row?.userId?.name || Constants.na
          )}
        </>
      ),
    },
    {
      name: Constants.email,
      cell: (row) => row?.email || Constants.na,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
  ];

  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      id,
    };
    dispatch(challengeUsers(data));
  };

  return (
    <>
      <div className="mt-3">
        <PageHeader pagetitle={Constants.users} />
      </div>
      <TableList
        columns={columns}
        data={programmeUserData}
        defaultSortFieldId
        highlightOnHover
      />
      {programmeuserlist?.count > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={programmeuserlist?.total}
          newOffset={handleOffset}
        />
      )}
    </>
  );
};

export default ProgrammeResponsePage;
