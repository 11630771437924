import {
  exercise,
  exerciseAdd,
  exerciseEdit,
  exerciseRemove,
} from "../../services/exercise.service";
import { types } from "./types";

export const exerciseInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exercise(data);
  if (response?.status) {
    const result = response?.data?.exerciseDetails;
    dispatch({ type: types.EXERCISE_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.EXERCISE_LIST,
      payload: [],
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const exerciseDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await exerciseRemove(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
