export const Constants = {
  accepts: "Accepts",
  accessToken: "accessToken",
  achievementType: "Achievement Type",
  actions: "Actions",
  activeTab: "activeTab",
  activityType: "Activity Type",
  activityTypeOptions: [
    { value: "Weight Lift", label: "Weight Lift" },
    { value: "Reps", label: "Reps" },
    { value: "Workout Duration", label: "Workout Duration" },
    { value: "Workout Count", label: "Workout Count" },
    { value: "Streaks", label: "Streaks" },
  ],
  add: "Add",
  addBadge: "Add Badge",
  addBodyGroup: "Add Body Group",
  addChallenge: "Add Challenge",
  addEquipment: "Add Equipment",
  addExercise: "Add Exercise",
  addExerciseCategory: "Add Body Part Category",
  addExercisePosition: "Add Exercise Position",
  addExerciseGoal: "Add Exercise Goal",
  addFaq: "Add FAQ",
  addForce: "Add Force",
  addGoal: "Add Goal",
  addJoint: "Add Avoid for Joint",
  addKey: "add",
  addMechanicsJoint: "Add Joint",
  addMovementPattern: "Add Movement Pattern",
  addMuscle: "Add Avoid for Muscle",
  addPrimaryJoint: "Add Primary Joint",
  addPrimaryMuscle: "Add Primary Muscle",
  addProgramme: "Add Programme",
  addSynergistMuscle: "Add Synergist Muscle",
  addTutorial: "Add Tutorial",
  addUser: "Add User",
  addWorkout: "Add Workout Builder",
  admin: "Admin",
  adminKey: "ADMIN",
  age: "Age",
  allow: "Allow",
  analyticsMonth: "30",
  analyticsNinetyDays: "90 days",
  analyticsOneYear: "1 year",
  analyticsSevenDays: "7 days",
  analyticsThirtyDays: "30 days",
  analyticsThreeMonths: "90",
  analyticsWeek: "7",
  analyticsYear: "365",
  ans: "Answer",
  apply: "Apply",
  areYouSure: "Are you sure?",
  avgAgeOfUsers: "Average Age of Users",
  back: "Back",
  badgeList: "Badge List",
  badges: "Badges",
  badgesKey: "badges",
  badgeAchievementOptions: [
    { value: "NEW_WORKOUT_MILESTONE", label: "New Workout Milestone" },
    { value: "NEW_STREAK_RECORD", label: "New Streak Record" },
    { value: "NEW_WEIGHT_RECORD", label: "New Weight Record" },
    { value: "NEW_PROGRESS_RECORD", label: "New Progress Record" },
    { value: "NEW_PERSONAL_RECORD", label: "New Personal Record" },
  ],
  badgeSubTypeOptions: [
    { value: "reps", label: "Reps" },
    { value: "timeUnderTension", label: "Time Under Tension" },
    { value: "weightLifted", label: "Weight Lifted" },
    { value: "caloriesBurned", label: "Calories Burned" },
  ],
  badgeTypeOptions: [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "lifetime", label: "Lifetime" },
    { value: "workout", label: "Workout" },
    { value: "streak", label: "Streak" },
    { value: "muscleGroup", label: "Body Part Category" },
  ],
  bodyGroup: "Body Group",
  bodyGroupKey: "body-group",
  bodyGroupList: "Body Group List",
  bodyPartCateKey: "body_cate",
  bodyParts: "Body Parts",
  bodyPartsKey: "body-parts",
  byEquipment: "By Equipment",
  byForce: "By Force",
  byMuscle: "By Muscle",
  caloriesBurnt: "Calories Burnt",
  cancel: "Cancel",
  category: "Category",
  challenge: "Challenge",
  challengeKey: "challenge",
  challengeList: "Challenge List",
  cms: "CMS",
  cmsKey: "cms",
  cmsDashboardKey: "dashboard_image",
  code: "Code",
  compoundSet: "compoundset",
  createdDate: "Created Date",
  createdTime: "Created Time",
  createRole: "Create Role",
  customer: "Customers",
  customerList: "Customer List",
  dashboard: "Dashboard",
  dashboardImage: "Dashboard Image",
  dashboardKey: "dashboard",
  dateFormat: "D MMM YYYY",
  delete: "Delete",
  deleted: "Deleted",
  deleteKey: "delete",
  description: "Description",
  detail: "Detail",
  dialCode: "Dial Code",
  dialCodes: "+91",
  disabled: "Disabled",
  dob: "Date of birth",
  downloadReport: "Download Reports",
  downloadReportKey: "download-report",
  edit: "Edit",
  editBadge: "Edit Badge",
  editBodyGroup: "Edit Body Group",
  editChallenge: "Edit Challenge",
  editDayWorkout: "Edit Day Workout",
  editEquipment: "Edit Equipment",
  editExerciseCategory: "Edit Body Part Category",
  editExercise: "Edit Exercise",
  editExerciseGoal: "Edit Exercise Goal",
  editExercisePosition: "Edit Exercise Position",
  editFaq: "Edit FAQ",
  editForce: "Edit Force",
  editGoal: "Edit Goal",
  editJoint: "Edit Avoid for Joint",
  editKey: "edit",
  editMechanicsJoint: "Edit Joint",
  editMovementPattern: "Edit Movement Pattern",
  editMuscle: "Edit Avoid for Muscle",
  editPrimaryJoint: "Edit Primary Joint",
  editPrimaryMuscle: "Edit Primary Muscle",
  editProgramme: "Edit Programme",
  editSynergistMuscle: "Edit Synergist Muscle",
  editTutorial: "Edit Tutorial",
  editUser: "Edit User",
  editWorkout: "Edit Workout Builder",
  email: "Email ID",
  endDate: "End Date",
  equipment: "Equipment",
  equipmentKey: "equipment",
  equipmentList: "Equipment List",
  exercise: "Exercise",
  exerciseKey: "exercise",
  exerciseList: "Exercise List",
  exerciseCategory: "Body Part Category",
  exerciseCategoryKey: "exer-category",
  exerciseCategoryList: "Body Part Category List",
  exerciseGoal: "Exercise Goal",
  exerciseGoalKey: "exer-goal",
  exerciseGoalList: "Exercise Goal List",
  exerciseName: "Exercise Name",
  exerciseCode: "Exercise Code",
  exerciseCodePs: "Exercise Code Ps",
  exercisePosition: "Exercise Position",
  exercisePositionKey: "exer-position",
  exercisePositionList: "Exercise Position List",
  exercises: "Exercises",
  experience: "Experience Level",
  export: "EXPORT",
  faq: "FAQ",
  faqKey: "faq",
  faqList: "FAQ List",
  force: "Force",
  forceKey: "force",
  forceList: "Force List",
  freqOptions: [
    { label: "Most Frequent", value: -1 },
    { label: "Least Frequent", value: 1 },
  ],
  frequency: "Frequency",
  frequentUsers: "Frequent Users",
  frequentUsersKey: "frequent-cust",
  getLeaner: "Get Leaner",
  getStronger: "Get Stronger",
  genderOptions: [
    { value: null, label: "All" },
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
  ],
  goalKey: "goals",
  goalList: "Goal List",
  goals: "Goal",
  goalTiming: "Goal TIming",
  goalTimingKey: "goal-timing",
  goalTimingType: [
    {
      value: "WEEKS",
      label: "Weeks",
    },
    {
      value: "MONTHS",
      label: "Months",
    },
    {
      value: "YEARS",
      label: "Years",
    },
  ],
  gender: "Gender",
  grantAccess: "Grant Access",
  gridKey: "grid",
  guidedVideo: "Guided Video",
  height: "Height",
  heightTypeCm: "CMS",
  heightTypeFt: "FEET",
  history: "History",
  historyKey: "history",
  id: "ID",
  image: "Image",
  invalid: "Invalid",
  invalidEmail: "Invalid Email",
  invalidEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  invalidTextRegex: /^[^<>]*$/,
  itemDeleted: "Item has been deleted",
  joiningDate: "Joining Date",
  joint: "Avoid for Joint",
  jointKey: "joint",
  jointList: "Joint List",
  level: "Level",
  levelOptions: [
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
  ],
  limitCount: 25,
  listKey: "list",
  login: "Login",
  manageRoles: "Manage Roles",
  manageRolesKey: "manage-roles",
  manageUsers: "Manage Users",
  manageUsersKey: "manage-cust",
  mechanics: "Mechanics",
  mechanicsKey: "mechanics",
  mechanicsJoint: "Joint",
  mechanicsJointKey: "mech-joint",
  mechanicsJointList: "Joint List",
  message: "Message",
  mode: "Mode",
  modeOptions: [
    { value: "STANDARD", label: "Standard" },
    { value: "ECCENTRIC", label: "Eccentric" },
    { value: "ISOKINETIC", label: "Isokinetic" },
    { value: "ELASTIC", label: "Elastic" },
    { value: "ROWING", label: "Rowing" },
  ],
  movementPattern: "Movement Pattern",
  movementPatternKey: "move-pattern",
  movementPatternList: "Movement Pattern List",
  muscle: "Avoid for Muscle",
  muscleKey: "muscle",
  muscleList: "Muscle List",
  na: "N/A",
  name: "Name",
  nameKey: "name",
  newSignups: "Total Signups",
  noRevert: "You won't be able to revert this!",
  notification: "Notification",
  notificationKey: "notification",
  notPublished: "Not Published",
  number: "Mobile Number",
  oops: "Oops!",
  other: "Other",
  paginationFalse: "pagination=false",
  password: "Password",
  passwordRequired: "Password must be 7 characters at minimum",
  passwordValidation:
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
  required: "This field cannot be empty",
  permission: {
    BADGE_ADD: "badges_add",
    BADGE_DELETE: "badges_delete",
    BADGE_EDIT: "badges_edit",
    BADGE_VIEW: "badges_view",
    BODY_PART_ADD: "body-parts_add",
    BODY_PART_DELETE: "body-parts_delete",
    BODY_PART_EDIT: "body-parts_edit",
    BODY_PART_VIEW: "body-parts_view",
    CHALLENGE_ADD: "challenge_add",
    CHALLENGE_DELETE: "challenge_delete",
    CHALLENGE_EDIT: "challenge_edit",
    CHALLENGE_VIEW: "challenge_view",
    CMS_ADD: "cms_add",
    CMS_DELETE: "cms_delete",
    CMS_EDIT: "cms_edit",
    CMS_VIEW: "cms_view",
    DASHBOARD_VIEW: "dashboard_view",
    DOWNLOAD_REPORT_VIEW: "download-report_view",
    EQUIPMENT_ADD: "equipment_add",
    EQUIPMENT_DELETE: "equipment_delete",
    EQUIPMENT_EDIT: "equipment_edit",
    EQUIPMENT_VIEW: "equipment_view",
    EXER_CATEGORY_ADD: "exer-category_add",
    EXER_CATEGORY_DELETE: "exer-category_delete",
    EXER_CATEGORY_EDIT: "exer-category_edit",
    EXER_CATEGORY_VIEW: "exer-category_view",
    EXER_GOAL_ADD: "exer-goal_add",
    EXER_GOAL_DELETE: "exer-goal_delete",
    EXER_GOAL_EDIT: "exer-goal_edit",
    EXER_GOAL_VIEW: "exer-goal_view",
    EXERCISE_ADD: "exercise_add",
    EXERCISE_DELETE: "exercise_delete",
    EXERCISE_EDIT: "exercise_edit",
    EXERCISE_VIEW: "exercise_view",
    GOAL_ADD: "goals_add",
    GOAL_DELETE: "goals_delete",
    GOAL_EDIT: "goals_edit",
    GOAL_VIEW: "goals_view",
    MECHANICS_ADD: "mechanics_add",
    MECHANICS_DELETE: "mechanics_delete",
    MECHANICS_EDIT: "mechanics_edit",
    MECHANICS_VIEW: "mechanics_view",
    NOTIFI_ADD: "notification_add",
    NOTIFI_VIEW: "notification_view",
    PROGRAMME_ADD: "programme_add",
    PROGRAMME_DELETE: "programme_delete",
    PROGRAMME_EDIT: "programme_edit",
    PROGRAMME_VIEW: "programme_view",
    REPORT_VIEW: "reports_view",
    ROLE_MGT_VIEW: "role_mgt_view",
    ROLE_MGT_EDIT: "role_mgt_edit",
    ROLE_MGT_DELETE: "role_mgt_delete",
    ROLE_MGT_ADD: "role_mgt_add",
    TUTORIAL_ADD: "tutorial_add",
    TUTORIAL_DELETE: "tutorial_delete",
    TUTORIAL_EDIT: "tutorial_edit",
    TUTORIAL_VIEW: "tutorial_view",
    CUSTOMER_VIEW: "users_view",
    WORKOUT_ADD: "workout_add",
    WORKOUT_DELETE: "workout_delete",
    WORKOUT_EDIT: "workout_edit",
    WORKOUT_VIEW: "workout_view",
  },
  permissionApiRoute: "/api/v1/",
  phoneNumber: "Phone Number",
  position: "Position",
  primaryJoint: "Primary Joint",
  primaryJointKey: "primary-joint",
  primaryJointList: "Primary Joint List",
  primaryMuscle: "Primary Muscle",
  primaryMuscleKey: "primary-muscle",
  primaryMuscleList: "Primary Muscle List",
  privacyPolicy: "Privay Policy",
  privacyPolicyKey: "privacy-policy",
  programme: "Programme",
  programmeKey: "programme",
  programmeList: "Programme List",
  programs: "Programs",
  progress: "Progress Tracking",
  published: "Published",
  pyramidSet: "pyramidset",
  ques: "Question",
  quickTrainWorkout: "Quick Train Workout",
  recentSignups: "Recent Signups",
  recommendedWeight: "Recommended Weight",
  regression: "Regression",
  reports: "Reports",
  reportsKey: "reports",
  reps: "Reps",
  reset: "Reset",
  rest: "Rest (Seconds)",
  revenue: "Revenue",
  role: "Role",
  roleId: "roleId",
  roleKey: "ROLE",
  roleMgt: "Role Management",
  roleMgtKey: "role_mgt",
  roleType: "role_type",
  search: "Search",
  searchByEmail: "Search by Email, Name",
  searchByQues: "Search by Question",
  searchByName: "Search by Name",
  searchByTitle: "Search by Title",
  select: "Select",
  selectAll: "Select All",
  selectDate: "Select Date",
  selectTime: "Select Time",
  setRule: "Set Rule",
  setRuleOptions: [
    {
      value: "instant",
      label: "Instant",
    },
    {
      value: "scheduled",
      label: "Scheduled",
    },
  ],
  sets: "Sets",
  signout: "Sign out",
  startDate: "Start Date",
  status: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
  statusOptions: [
    { label: "All", value: null },
    { label: "Published", value: "Active" },
    { label: "Not Published", value: "Inactive" },
  ],
  statuss: "Status",
  statusKey: "Publish",
  strengthAssessment: "Strength Assessment",
  strengthAssessmentKey: "strength_assess",
  submit: "Submit",
  subtitle: "Sub Title",
  subType: "Sub Type",
  success: "Success",
  superset: "superset",
  synergistMuscle: "Synergist Muscle",
  synergistMuscleKey: "synergist-muscle",
  synergistMuscleList: "Synergist Muscle List",
  tenure: "Tenure (in weeks)",
  tenureC: "Tenure",
  thumbnail: "Thumbnail",
  timeFormat: "hh:mm A",
  timeUnderTension: "Time Under Tension",
  title: "Title",
  totalActiveUsers: "Active Users",
  totalActiveUsersMale: "Active Users (Male)",
  totalActiveUsersFemale: "Active Users (Female)",
  totalEnrolledUsers: "Total Enrolled Users",
  totalExerciseVideos: "Total Exercise Videos",
  totalProgrammes: "Total Programs",
  totalRegisteredUsers: "Registered Users",
  totalReps: "Total Reps",
  totalRepsSplit: "Reps Split by Body Part",
  totalRevenue: "Total Revenue",
  totalTransactions: "Total Transactions",
  totalWeightLifted: "Total Weight Lifted (KGS)",
  totalWeightLiftedSplit: "Weight Lifted split by Body Part (KGS)",
  totalWorkouts: "Total Workouts",
  totalWorkoutSessionDuration: "Average Workout Session Duration (Minutes)",
  totalWorkoutSessions: "Workout Sessions",
  triSet: "triset",
  tutorial: "Tutorial",
  tutorialKey: "tutorial",
  tutorialList: "Tutorial List",
  type: "Type",
  typeOptions: [
    {
      value: "WEIGHT",
      label: "Weight",
    },
    {
      value: "CALORIES",
      label: "Calories",
    },
    {
      value: "MINUTES",
      label: "Minutes",
    },
    {
      value: "DISTANCE",
      label: "Distance",
    },
  ],
  unit: "Unit",
  upload: "Upload",
  userAgreement: "User Agreement",
  userAgreementKey: "user-agreement",
  users: "Users",
  usersKey: "users",
  usersList: "User List",
  validEmailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  validPasswordRegex:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%#*?&]{7,}$/,
  validPhoneNumber: /^[6-9]\d{9}$/,
  value: "Value",
  valueOption: [
    { value: "LBS", label: "LBS" },
    { value: "KGS", label: "KGS" },
    { value: "KCAL", label: "KCAL" },
    { value: "UNIT", label: "UNIT" },
  ],
  video: "Video",
  view: "View",
  viewKey: "view",
  weight: "Weight",
  weightTypeKgs: "KGS",
  weightTypeLbs: "LBS",
  welcome: "Welcome",
  width: "Width",
  workout: "Workout Builder",
  workoutAnalytics: "Workout Analytics",
  workoutAnalyticsKey: "report-work-analytics",
  workoutCount: "Workout Count",
  workoutKey: "workout",
  workoutList: "Workout Builder List",
  workoutSessionSplit: "Workout Session Split",
  workoutSessionSplitInMins: "Workout Session Split (Minutes)",
  workoutSessionSplitKey: "workout-session-split",
  youWantToSignOut: "You want to sign out?",
};
