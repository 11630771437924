import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../helper/HttpService";
import { Constants } from "../components/Common/Constants";

export const users = async (data) => {
  const url = `${ApiUrl.USER_LIST}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}&limit=${data?.limit || Constants.limitCount}`
  }${data && data?.search ? `&search=${data?.search}` : ""}${
    data?.gender ? `&gender=${data?.gender}` : ""
  }${
    data?.start_date
      ? `&start_date=${data?.start_date}&end_date=${data?.end_date}`
      : ""
  }`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const fetchUserDetail = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.USER_DETAIL +
      `?userId=${data?.id}&days=${data?.days}${
        data?.bodyPart ? `&bodyPartId=${data?.bodyPart}` : ""
      }${data?.assessExer ? `&exerciseId=${data?.assessExer}` : ""}`
  );
  return response;
};
