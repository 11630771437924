import React from "react";
import { useDrop } from "react-dnd";
import { Constants } from "../../Common/Constants";
import usePermissions from "../../Common/Functions/usePermissions";

const DroppableDay = ({ day, onDrop, deleteWorkout }) => {
  // Permissions
  const addPermission = usePermissions(Constants.permission.PROGRAMME_ADD);
  const deletePermission = usePermissions(
    Constants.permission.PROGRAMME_DELETE
  );

  const [{ isOver }, drop] = useDrop(
    {
      accept: "workout",
      drop: (item) => (addPermission ? onDrop(item) : null),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    },
    [addPermission]
  );

  return (
    <div ref={drop} className={`list-cards ${isOver ? "highlight" : ""}`}>
      <div className="img_name">
        <div className="img_round">
          <i className="icofont-dumbbell"></i>
        </div>
        <span>{`Day ${day?.day}`}</span>
      </div>
      <div className="name_ex">
        <p>
          {day?.workout?.workoutId
            ? `${day?.workout?.workoutId} (${day?.workout?.name})`
            : Constants.na}
        </p>
      </div>
      {day?.workout?.workoutId && deletePermission && (
        <div className="img_dots">
          <button
            className="trash-btns"
            type="button"
            onClick={() => deleteWorkout(day)}
          >
            <i className="icofont-trash"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default DroppableDay;
