export const RoutesUrl = {
  badges: "/badges",
  bodyGroup: "/body-group",
  challenge: "/challenge",
  dashboard: "/dashboard",
  dashboardImage: "/dashboard_image",
  equipment: "/equipment",
  exercise: "/exercise",
  exerciseCategory: "/exer-category",
  exerciseGoal: "/exer-goal",
  exercisePosition: "/exer-position",
  faq: "/faq",
  force: "force",
  frequentUsers: "frequent-cust",
  goals: "/goals",
  goalTiming: "/goal-timing",
  joint: "/joint",
  manageRoles: "/manage-roles",
  manageUsers: "/manage-cust",
  mechanicsJoint: "/mech-joint",
  movementPattern: "/move-pattern",
  muscle: "/muscle",
  notification: "/notification",
  pageNotFound: "/page-404",
  primaryJoint: "/primary-joint",
  primaryMuscle: "/primary-muscle",
  privacyPolicy: "/privacy-policy",
  programme: "/programme",
  signIn: "/sign-in",
  staticDashboard: "/static-dashboard",
  strenghAssess: "/strength_assess",
  synergistMuscle: "/synergist-muscle",
  tutorial: "/tutorial",
  userAgreement: "user-agreement",
  users: "/users",
  workout: "/workout",
  workoutAnalytics: "/report-work-analytics",
  workoutBodyPartCate: "/body_cate",
  workoutSessionSplit: "/workout-session-split",
};
