import {
  dayWorkout,
  dayWorkoutAdd,
  dayWorkoutRemove,
  fetchProgrammeDetail,
  programme,
  programmeAdd,
  programmeEdit,
  programmeRemove,
  usersProgramme,
} from "../../services/programme.service";
import { types } from "./types";

export const programmeInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await programmeAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const programmeList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await programme(data);
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.PROGRAMME_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.PROGRAMME_LIST,
      payload: [],
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const programmeUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await programmeEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const programmeDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await programmeRemove(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const getProgrammeDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await fetchProgrammeDetail(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.PROGRAMME_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.PROGRAMME_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const dayWorkoutInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await dayWorkoutAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const dayWorkoutList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await dayWorkout(data);
  if (response?.status) {
    const result = response?.data?.weekData;
    dispatch({ type: types.DAY_WORKOUT_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.DAY_WORKOUT_LIST,
      payload: [],
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const dayWorkoutDelete = (weekId, day) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await dayWorkoutRemove(weekId, day);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const programmeUsers = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await usersProgramme(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.PROGRAMME_USER_LIST, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.PROGRAMME_USER_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
