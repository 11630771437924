import { Routes, Route, Navigate } from "react-router-dom";
import "./assets/scss/main.scss";
import Page404 from "./components/Auth/Page404";
import Header from "./components/Common/Header";
import { RoutesUrl } from "./components/Common/RoutesUrl";
import SignIn from "./components/Auth/SignIn";
import UsersList from "./components/Users";
import Dashboard from "./components/Dashboard/Dashboard.js";
import React from "react";
import GoalList from "./components/Goals/index.js";
import EquipmentList from "./components/Equipment/index.js";
import ExerciseCategoryList from "./components/ExerciseCategory/index.js";
import MuscleList from "./components/BodyParts/Muscle/index.js";
import JointList from "./components/BodyParts/Joint/index.js";
import BodyGroupList from "./components/BodyParts/BodyGroup/index.js";
import PrimaryJointList from "./components/BodyParts/PrimaryJoint/index.js";
import PrimaryMuscleList from "./components/BodyParts/PrimaryMuscle/index.js";
import SynergistMuscleList from "./components/BodyParts/SynergistMuscle/index.js";
import ExercisePositionList from "./components/BodyParts/ExercisePosition/index.js";
import ExerciseGoalList from "./components/ExerciseGoal/index.js";
import GoalTimingForm from "./components/CMS/GoalTimingForm.js";
import ExerciseList from "./components/Exercise/index.js";
import MechanicsJointList from "./components/Mechanics/Joint/index.js";
import MovementPatternList from "./components/Mechanics/MovementPattern/index.js";
import ForceList from "./components/Mechanics/Force/index.js";
import TutorialList from "./components/Tutorial/index.js";
import ChallengeList from "./components/Challenge/index.js";
import WorkoutList from "./components/Workout/index.js";
import AddExercise from "./components/Workout/Detail/AddExercise.js";
import ProgrammeList from "./components/Programme/index.js";
import UserDetail from "./components/Users/Detail.js";
import BadgeList from "./components/Badge/index.js";
import Notification from "./components/Notification/index.js";
import ChallengeDetail from "./components/Challenge/ChallengeDetail/index.js";
import PrivacyPolicy from "./components/CMS/PrivacyPolicy.js";
import UserAgreement from "./components/CMS/UserAgreement.js";
import ProgrammeDetail from "./components/Programme/ProgrammeDetail/index.js";
import FaqList from "./components/CMS/Faq/index.js";
import ManageUsers from "./components/RoleManagement/Users/index.js";
import ManageRoles from "./components/RoleManagement/Roles/index.js";
import BadgeDetail from "./components/Badge/BadgeDetail/index.js";
import FrequentUsers from "./components/Reports/FrequentUsers.js";
import WorkoutAnalytics from "./components/Reports/WorkoutAnalytics/index.js";
import DashboardImage from "./components/CMS/DashboardImage.js";
import WorkoutSessionSplit from "./components/Reports/WorkoutSessionSplit/index.js";
import BodyPartCategory from "./components/Reports/BodyPartCategory/index.js";
import StrengthAssessment from "./components/Reports/StrengthAssessment/index.js";
import StaticDashboard from "./components/Dashboard/StaticDashboard.js";

const MainRoutes = (props) => {
  const { activekey } = props;

  return (
    <div
      className={`main space-side header-bg ${
        activekey === RoutesUrl.signIn ? "sign-in-bg" : ""
      }`}
    >
      {activekey === RoutesUrl.pageNotFound ||
      activekey === RoutesUrl.signIn ? (
        ""
      ) : (
        <Header />
      )}
      <div className="body d-flex">
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path={RoutesUrl.badges} element={<BadgeList />} />
          <Route path={`${RoutesUrl.badges}/:id`} element={<BadgeDetail />} />
          <Route path={RoutesUrl.bodyGroup} element={<BodyGroupList />} />
          <Route path={RoutesUrl.challenge} element={<ChallengeList />} />
          <Route
            path={`${RoutesUrl.challenge}/:id`}
            element={<ChallengeDetail />}
          />
          <Route path={RoutesUrl.dashboard} element={<Dashboard />} />
          <Route path={RoutesUrl.dashboardImage} element={<DashboardImage />} />
          <Route path={RoutesUrl.equipment} element={<EquipmentList />} />
          <Route path={RoutesUrl.exercise} element={<ExerciseList />} />
          <Route
            path={RoutesUrl.exerciseCategory}
            element={<ExerciseCategoryList />}
          />
          <Route path={RoutesUrl.exerciseGoal} element={<ExerciseGoalList />} />
          <Route
            path={RoutesUrl.exercisePosition}
            element={<ExercisePositionList />}
          />
          <Route path={RoutesUrl.faq} element={<FaqList />} />
          <Route path={RoutesUrl.force} element={<ForceList />} />
          <Route path={RoutesUrl.frequentUsers} element={<FrequentUsers />} />
          <Route path={RoutesUrl.goals} element={<GoalList />} />
          <Route path={RoutesUrl.goalTiming} element={<GoalTimingForm />} />
          <Route path={RoutesUrl.joint} element={<JointList />} />
          <Route path={`${RoutesUrl.manageRoles}`} element={<ManageRoles />} />
          <Route path={`${RoutesUrl.manageUsers}`} element={<ManageUsers />} />
          <Route
            path={RoutesUrl.mechanicsJoint}
            element={<MechanicsJointList />}
          />
          <Route
            path={RoutesUrl.movementPattern}
            element={<MovementPatternList />}
          />
          <Route path={RoutesUrl.muscle} element={<MuscleList />} />
          <Route path={RoutesUrl.notification} element={<Notification />} />
          <Route path={RoutesUrl.pageNotFound} element={<Page404 />} />
          <Route path={RoutesUrl.primaryJoint} element={<PrimaryJointList />} />
          <Route
            path={RoutesUrl.primaryMuscle}
            element={<PrimaryMuscleList />}
          />
          <Route path={RoutesUrl.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={RoutesUrl.programme} element={<ProgrammeList />} />
          <Route
            path={`${RoutesUrl.programme}/:id`}
            element={<ProgrammeDetail />}
          />
          <Route
            path={RoutesUrl.synergistMuscle}
            element={<SynergistMuscleList />}
          />
          <Route path={RoutesUrl.signIn} element={<SignIn />} />
          <Route
            path={RoutesUrl.staticDashboard}
            element={<StaticDashboard />}
          />
          <Route
            path={RoutesUrl.strenghAssess}
            element={<StrengthAssessment />}
          />
          <Route path={RoutesUrl.tutorial} element={<TutorialList />} />
          <Route path={RoutesUrl.userAgreement} element={<UserAgreement />} />
          <Route path={RoutesUrl.users} element={<UsersList />} />
          <Route path={`${RoutesUrl.users}/:id`} element={<UserDetail />} />
          <Route path={RoutesUrl.workout} element={<WorkoutList />} />
          <Route path={`${RoutesUrl.workout}/:id`} element={<AddExercise />} />
          <Route
            path={`${RoutesUrl.workoutAnalytics}`}
            element={<WorkoutAnalytics />}
          />
          <Route
            path={`${RoutesUrl.workoutBodyPartCate}`}
            element={<BodyPartCategory />}
          />
          <Route
            path={`${RoutesUrl.workoutSessionSplit}`}
            element={<WorkoutSessionSplit />}
          />
          <Route
            path="*"
            element={<Navigate replace to={RoutesUrl.pageNotFound} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default MainRoutes;
