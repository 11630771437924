import React from "react";
import { Constants } from "../Common/Constants";
import BarGraph from "../Common/Graphs/BarGraph";
import { useSelector } from "react-redux";
import AreaGraph from "../Common/Graphs/AreaGraph";

const Yearly = () => {
  const { dashboardData } = useSelector((store) => store.Dashboard);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <BarGraph
          data={dashboardData?.registeredUserYearly}
          title={Constants.totalRegisteredUsers}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={dashboardData?.totalActiveUserYear}
          title={Constants.totalActiveUsers}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={dashboardData?.activeMaleUsersyear}
          title={Constants.totalActiveUsersMale}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={dashboardData?.activeFeMaleUsersyear}
          title={Constants.totalActiveUsersFemale}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={dashboardData?.lastyearWorkoutSession}
          title={Constants.totalWorkoutSessions}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={dashboardData?.avgWorkoutDurationYearly}
          title={Constants.totalWorkoutSessionDuration}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Yearly;
