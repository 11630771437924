import { Form, Formik, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../../Common/Constants";
import {
  getWorkoutDetail,
  workoutExerciseUpdate,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import TextField from "../../Common/Fields/TextField";
import ButtonCustom from "../../Common/Fields/ButtonCustom";
import { useParams } from "react-router-dom";
import usePermissions from "../../Common/Functions/usePermissions";

const WorkoutExerciseForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { workoutExerciseSingle, workoutpopupmodel } = useSelector(
    (state) => state.Workout
  );
  const [sets, setSets] = useState();
  // Permissions
  const addPermission = usePermissions(Constants.permission.WORKOUT_ADD);
  const editPermission = usePermissions(Constants.permission.WORKOUT_EDIT);

  // Initial values for the form
  const [initialValues, setInitialValues] = useState({
    sets: "",
    setDetails: [],
  });

  // Validation schema for form fields
  const validationSchema = Yup.object().shape({
    sets: Yup.number().required(Constants.required),
    setDetails: Yup.array().of(
      Yup.object().shape({
        rest: Yup.number().required(Constants.required),
        reps: Yup.number().required(Constants.required),
        recommendedWeight: Yup.string().required(Constants.required),
      })
    ),
  });

  useEffect(() => {
    // Set initial values to pre-fill Workout form
    if (workoutExerciseSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (workoutExerciseSingle[key]) {
          editValue[key] = workoutExerciseSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [workoutExerciseSingle, sets]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        values.id = workoutpopupmodel?.setId || workoutExerciseSingle?._id;
        if (workoutpopupmodel?.type) {
          values.type = workoutpopupmodel?.type;
        }
        // Update Workout API
        dispatch(workoutExerciseUpdate(values)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.WORKOUT_ADD_EXER_SINGLE, payload: "" });
            dispatch({
              type: types.WORKOUT_ADD_EXER_FORM_MODEL,
              payload: "",
            });
          }
          dispatch(getWorkoutDetail({ id }));
        });
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="number"
              name="sets"
              className="form-control"
              errors={touched?.sets ? errors?.sets : undefined}
              touched={touched?.sets}
              label={Constants.sets}
              onChange={(e) => {
                const numSets = parseInt(e.target.value);
                setFieldValue(
                  "sets",
                  e.target.value ? numSets : e.target.value
                );
                setSets(e.target.value);
                // Generate updated set details, retaining only if workoutExerciseSingle?.setDetails exists
                const updatedSetDetails = Array.from(
                  { length: numSets },
                  (_, i) => ({
                    name: `Set ${i + 1}`,
                    rest: workoutExerciseSingle?.setDetails?.[i]?.rest || "",
                    recommendedWeight:
                      workoutExerciseSingle?.setDetails?.[i]
                        ?.recommendedWeight || "",
                    reps: workoutExerciseSingle?.setDetails?.[i]?.reps || "",
                  })
                );
                setFieldValue("setDetails", updatedSetDetails);
              }}
            />
          </div>
          {/* Dynamically render rest and recommended weight fields for each set */}
          <FieldArray name="setDetails">
            {({ remove, push }) => (
              <>
                {values?.setDetails?.map((set, index) => (
                  <div key={index} className="col-md-12">
                    <h5 className="head_bg">{set.name}</h5>
                    <div className="row">
                      <div className="col-md-4 mb-2">
                        <TextField
                          type="number"
                          name={`setDetails[${index}].rest`}
                          className="form-control"
                          errors={
                            touched?.setDetails?.[index]?.rest
                              ? errors?.setDetails?.[index]?.rest
                              : undefined
                          }
                          touched={touched?.setDetails?.[index]?.rest}
                          label={Constants.rest}
                        />
                      </div>
                      <div className="col-md-4 mb-2">
                        <TextField
                          type="string"
                          name={`setDetails[${index}].recommendedWeight`}
                          className="form-control"
                          errors={
                            touched?.setDetails?.[index]?.recommendedWeight
                              ? errors?.setDetails?.[index]?.recommendedWeight
                              : undefined
                          }
                          touched={
                            touched?.setDetails?.[index]?.recommendedWeight
                          }
                          label={Constants.recommendedWeight}
                        />
                      </div>
                      <div className="col-md-4 mb-2">
                        <TextField
                          type="number"
                          name={`setDetails[${index}].reps`}
                          className="form-control"
                          errors={
                            touched?.setDetails?.[index]?.reps
                              ? errors?.setDetails?.[index]?.reps
                              : undefined
                          }
                          touched={touched?.setDetails?.[index]?.reps}
                          label={Constants.reps}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>
          {((addPermission && !workoutExerciseSingle?._id) ||
            (editPermission && workoutExerciseSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default WorkoutExerciseForm;
