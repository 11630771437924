import { Constants } from "../../components/Common/Constants";
import {
  loginUser,
  logoutUser,
  userPermission,
} from "../../services/auth.service";
import { types } from "./types";

export const login = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await loginUser(data);
  if (response?.status) {
    const userData = response.data;
    const obj = {};
    userData?.user?.permissionData?.forEach(
      (field) => (obj[field?.key] = field.is_enabled)
    );
    dispatch({ type: types.PERMISSION_OBJECT, payload: obj });
    localStorage.setItem(Constants.roleId, userData?.user?.role);
    localStorage.setItem(
      Constants.roleType,
      userData?.user?.roleName || userData?.user?.roles
    );
    localStorage.setItem(Constants.accessToken, userData?.token);
    localStorage.setItem(
      Constants.nameKey,
      userData?.user?.role ? userData?.user?.name : Constants.admin
    );
    dispatch({ type: types.LOGIN_SUCCESS, payload: userData });
    dispatch({ type: types.LOADER_HIDE });
    return { data: userData };
  } else {
    dispatch({ type: types.LOGIN_FAIL, payload: { error: response.error } });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const userPermissions = () => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await userPermission();
  if (response?.status) {
    const permissionData = response.data.permissions;
    const obj = {};
    permissionData?.forEach((field) => (obj[field?.key] = field.is_enabled));
    dispatch({ type: types.PERMISSION_OBJECT, payload: obj });
    dispatch({ type: types.LOADER_HIDE });
  } else {
    dispatch({ type: types.LOADER_HIDE });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await logoutUser();
  if (response?.status) {
    dispatch({ type: types.LOADER_HIDE });
    dispatch({ type: types.LOGOUT });
    return { data: response?.data?.statusCode };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
