import { types } from "../Actions/types";

const initialState = {
  frequentuserlist: [],
  workoutanalytics: {},
};

const Reports = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FREQUENT_USER_LIST:
      return {
        ...state,
        frequentuserlist: payload,
      };
    case types.WORKOUT_ANALYTICS:
      return {
        ...state,
        workoutanalytics: payload,
      };

    default:
      return state;
  }
};

export default Reports;
