/* eslint-disable indent */
import React from "react";
import { useSelector } from "react-redux";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import titleCase from "../Common/Functions/titleCase";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";

const RecentSignups = () => {
  const { dashboardData } = useSelector((store) => store.Dashboard);

  // Columns for User list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) => i + 1,
    },
    {
      name: Constants.email,
      selector: (row) => (
        <Link to={`${RoutesUrl.users}/${row?.userData?._id}`}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.userData?.email}
          >
            {row?.email}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.name,
      selector: (row) => row?.name || Constants.na,
    },
    {
      name: Constants.gender,
      selector: (row) => titleCase(row?.gender),
    },
    {
      name: Constants.number,
      selector: (row) => (row?.mobile ? `+91 ${row?.mobile}` : Constants.na),
    },
  ];

  return (
    <div className="mb-4">
      <div className="mt-3">
        <PageHeader pagetitle={Constants.recentSignups} />
      </div>
      <TableList
        columns={columns}
        data={dashboardData.recentSignUps}
        defaultSortFieldId
        highlightOnHover
      />
    </div>
  );
};

export default RecentSignups;
