import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import {
  bodyGroupDelete,
  bodyGroupList,
  bodyGroupUpdate,
} from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { deleteConfirmBox } from "../../Common/Functions/deleteConfirmBox";
import { Constants } from "../../Common/Constants";
import EditListButton from "../../Common/Fields/EditListButton";
import DeleteListButton from "../../Common/Fields/DeleteListButton";
import PageHeader from "../../Common/PageHeader";
import TableList from "../../Common/UiModel/TableList";
import ReactPagination from "../../Common/Fields/ReactPagination";
import ModalPopup from "../../Common/UiModel/ModalPopup";
import BodyGroupForm from "./BodyGroupForm";
import { Link } from "react-router-dom";
import ReactSelect from "../../Common/Fields/ReactSelect";
import ToggleListField from "../../Common/Fields/ToggleListField";
import responseMethod from "../../Common/Functions/responseMethod";
import usePermissions from "../../Common/Functions/usePermissions";

const BodyGroupList = () => {
  const dispatch = useDispatch();
  const { bodygrouplist, popupmodel } = useSelector((state) => state.BodyGroup);
  const [bodyGroupData, setBodyGroupData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState(
    Constants.statusOptions?.[0]
  );
  // Permissions
  const addPermission = usePermissions(Constants.permission.BODY_PART_ADD);
  const editPermission = usePermissions(Constants.permission.BODY_PART_EDIT);
  const deletePermission = usePermissions(
    Constants.permission.BODY_PART_DELETE
  );

  useEffect(() => {
    // Body Group list api
    dispatch(bodyGroupList());
    dispatch({
      type: types.BODY_GROUP_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for Body Group from store
    setBodyGroupData(bodygrouplist?.items);
  }, [bodygrouplist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Function to hit Body Group list
  const getData = (searchKey) => {
    const data = { search: searchKey || "", status: selectedStatus.value };
    dispatch(bodyGroupList(data));
  };

  const onDelete = (delete_id) => {
    // Delete Body Group
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(bodyGroupDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            const data = { search, status: selectedStatus.value };
            dispatch(bodyGroupList(data));
            setPageIndex(1);
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to handle edit functionality for Body Group
  const handleEdit = (row) => {
    dispatch({
      type: types.BODY_GROUP_FORM_MODEL,
      payload: {
        msg: Constants.editBodyGroup,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.BODY_GROUP_SINGLE,
      payload: row,
    });
  };

  const triggerToggle = (rowData) => {
    // Trigger status toggle for news
    const data = {
      id: rowData?._id,
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(bodyGroupUpdate(data)).then((response) => {
      const data = { search, status: selectedStatus.value };
      responseMethod(response, dispatch, bodyGroupList, data);
    });
  };

  // Columns to Body Group Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.statuss,
      cell: (row) =>
        row.status === Constants.status.ACTIVE
          ? Constants.published
          : Constants.notPublished,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit, delete Body Group
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            handleEdit(row);
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Delete the action if user does not have permission
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      status: selectedStatus.value,
      search,
    };
    dispatch(bodyGroupList(data));
  };

  const handleStatus = (status) => {
    setSelectedStatus(status);
    const data = {
      search,
      status: status?.value,
    };
    setPageIndex(1);
    dispatch(bodyGroupList(data));
  };

  const onResetFilter = () => {
    setSearch("");
    setSelectedStatus(Constants.statusOptions?.[0]);
    setPageIndex(1);
    dispatch(bodyGroupList());
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.bodyGroupList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.BODY_GROUP_FORM_MODEL,
              payload: { msg: Constants.addBodyGroup, show: true },
            });
          }}
        />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div className="col-md-2">
          <ReactSelect
            label={`${Constants.statuss}`}
            required={false}
            options={Constants.statusOptions}
            onChange={(selected) => handleStatus(selected)}
            value={selectedStatus}
          />
        </div>
        <div className="col-md-2">
          <label className="form-label">{Constants.search}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              setPageIndex(1);
              if (event.target.value === "") {
                setSearch("");
                getData("");
              }
            }}
          ></input>
        </div>
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={bodyGroupData}
        defaultSortFieldId
        highlightOnHover
      />
      {bodygrouplist?.totalCount > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={bodygrouplist?.totalCount}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
          pageIndex={pageIndex - 1}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.BODY_GROUP_SINGLE, payload: "" });
          dispatch({ type: types.BODY_GROUP_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<BodyGroupForm />}
      />
    </div>
  );
};

export default BodyGroupList;
