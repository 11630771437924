import { types } from "../Actions/types";

const initialState = {
  roleslist: [],
  roleuserslist: [],
  permissionlist: [],
  roleUsersSingle: {},
  popupmodel: "",
};

const Roles = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ROLES_LIST:
      return {
        ...state,
        roleslist: payload,
      };

    case types.ROLE_USERS_LIST:
      return {
        ...state,
        roleuserslist: payload,
      };
    case types.ROLE_USERS_SINGLE:
      return {
        ...state,
        roleUsersSingle: payload,
      };
    case types.ROLE_USERS_FORM_MODEL:
      return {
        ...state,
        popupmodel: payload,
      };
    case types.PERMISSION_LIST:
      return {
        ...state,
        permissionlist: payload,
      };

    default:
      return state;
  }
};

export default Roles;
