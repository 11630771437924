import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import {
  tutorialInsert,
  tutorialList,
  tutorialUpdate,
} from "../../Redux/Actions";
import FileAwsUpload from "../Common/Fields/FileAwsUpload";
import ViewImageFile from "../Common/Fields/ViewImageFile";
import RequiredIcon from "../Common/Fields/RequiredIcon";
import VideoTusUpload from "../Common/Fields/VideoTusUpload";
import ViewVideoFile from "../Common/Fields/ViewVideoFile";
import usePermissions from "../Common/Functions/usePermissions";

const TutorialForm = () => {
  const dispatch = useDispatch();
  const { tutorialSingle } = useSelector((state) => state.Tutorial);
  // Permissions
  const addPermission = usePermissions(Constants.permission.TUTORIAL_ADD);
  const editPermission = usePermissions(Constants.permission.TUTORIAL_EDIT);

  // Initial values to tutorial form
  const [initialValues, setInitialValues] = useState({
    name: "",
    position: "",
    thumbnail: "",
    video: "",
  });

  // Validations for tutorial form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(Constants.required),
    position: Yup.number().required(Constants.required),
    thumbnail: Yup.string().required(Constants.required),
    video: Yup.string().required(Constants.required),
  });

  useEffect(() => {
    // Set initial values to pre fill tutorial form
    if (tutorialSingle?._id) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (tutorialSingle[key]) {
          editValue[key] = tutorialSingle[key];
        }
        setInitialValues(editValue);
      }
    }
  }, [tutorialSingle]);

  const handleFileChange = (file, setFieldValue, field) => {
    // Add  thumbnail for tutorial
    setFieldValue(field, file?.[0]);
  };

  const handleVideoChange = (file, setFieldValue, field) => {
    setFieldValue(field, file);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Add or update tutorial API
        let submitFunc = tutorialInsert(values);
        if (tutorialSingle._id) {
          values.id = tutorialSingle._id;
          submitFunc = tutorialUpdate(values);
        }
        dispatch(submitFunc).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            dispatch({
              type: types.SUCCESS_ALERT_VISIBLE,
              payload: response.message,
            });
            dispatch({ type: types.TUTORIAL_SINGLE, payload: "" });
            dispatch({ type: types.TUTORIAL_FORM_MODEL, payload: "" });
          }
          dispatch(tutorialList());
        });
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form className="row">
          <div className="col-md-6 mb-2">
            <TextField
              type="text"
              name="name"
              className="form-control"
              errors={touched?.name ? errors?.name : undefined}
              touched={touched?.name}
              label={Constants.name}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextField
              type="number"
              name="position"
              className="form-control"
              errors={touched?.position ? errors?.position : undefined}
              touched={touched?.position}
              label={Constants.position}
            />
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.thumbnail}`}</label>
            <RequiredIcon />
            <div className="out-cr">
              <div className="width-outer-up">
                {values && !values?.thumbnail && (
                  <FileAwsUpload
                    fileType={[".jpeg", ".png", ".jpg"]}
                    width="381"
                    height="426"
                    errors={touched?.thumbnail ? errors?.thumbnail : undefined}
                    touched={touched?.thumbnail}
                    label={`${Constants.upload} ${Constants.thumbnail}`}
                    handleFile={(files) =>
                      handleFileChange(files, setFieldValue, "thumbnail")
                    }
                    maxFiles={1}
                  />
                )}
              </div>
              <div className="after-up">
                {values && values?.thumbnail && (
                  <ViewImageFile
                    file={values?.thumbnail}
                    onClick={() => setFieldValue("thumbnail", "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <label className="form-label">{`${Constants.video}`}</label>
            <RequiredIcon />
            <div>
              {values && !values?.video && (
                <VideoTusUpload
                  errors={touched?.video ? errors?.video : undefined}
                  touched={touched?.video}
                  label={`${Constants.upload} ${Constants.video}`}
                  handleFile={(video) =>
                    handleVideoChange(video, setFieldValue, "video")
                  }
                />
              )}
              {values && values?.video && (
                <ViewVideoFile
                  file={values?.video}
                  onClick={() => {
                    setFieldValue("video", "");
                  }}
                />
              )}
            </div>
          </div>
          {((addPermission && !tutorialSingle?._id) ||
            (editPermission && tutorialSingle?._id)) && (
            <ButtonCustom label={Constants.submit} type="submit" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default TutorialForm;
