import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const exerciseGoalAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.EXERCISE_GOAL, data);
  return response;
};

export const exerciseGoalEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.EXERCISE_GOAL, data);
  return response;
};

export const exerciseGoalRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.EXERCISE_GOAL + `/${id}`
  );
  return response;
};

export const exerciseGoal = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.EXERCISE_GOAL}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}${
      data?.status ? `&status=${data?.status}` : ""
    }`
  );
  return response;
};
