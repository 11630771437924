import { types } from "../Actions/types";

const initialState = {
  dashboardData: {},
};

const Dashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: payload,
      };

    default:
      return state;
  }
};

export default Dashboard;
