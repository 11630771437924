import React, { useState } from "react";
import { useSelector } from "react-redux";
import CardBlock from "./CardBlock";
import PageHeader from "../Common/PageHeader";
import { Constants } from "../Common/Constants";
import Analytics from "./Analytics";
import dayjs from "dayjs";
import { RoutesUrl } from "../Common/RoutesUrl";
import axios from "axios";
import { ApiUrl } from "../Common/ApiUrl";
import Spinner from "../../assets/images/Spinner.gif";

const UserDetail = () => {
  const { userSingle } = useSelector((store) => store.Users);
  const [loader, setLoader] = useState(false);

  const downloadCustomWorkout = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${ApiUrl.USER_CUSTOM_WORKOUT}?userId=${userSingle?.user?._id}`,
        {
          baseURL: `${process.env.REACT_APP_API_URL}`,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Constants.accessToken
            )}`,
          },
        }
      );
      setLoader(false);
      // Create a Blob from the data and generate a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `CustomWorkout_${userSingle?.user?.userName}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setLoader(false);
      console.error("Error downloading the Excel file:", error);
    }
  };

  return (
    <div className="container-xxl mb-4">
      <PageHeader
        pagetitle={userSingle?.user?.userName || Constants.na}
        list={RoutesUrl?.users}
      />
      {/* Personal Details */}
      <CardBlock user={userSingle?.user} />
      <div className="row">
        {/* Goals */}
        <div className="col-md-4 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-red card-user-details">
            <h4 className="grey_bg">{`${Constants.goals}`}</h4>
            <div className="h5 line-b">
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Type : ${userSingle?.userGoalWithExerciseDetails?.[0]?.type}`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Durations : ${userSingle?.userGoalWithExerciseDetails?.[0]?.duration}`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Start Date :  ${dayjs(
                  userSingle?.userGoalWithExerciseDetails?.[0]?.startDate
                ).format(Constants.dateFormat)} at ${dayjs(
                  userSingle?.userGoalWithExerciseDetails?.[0]?.startDate
                ).format(Constants.timeFormat)}`}</div>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-user-details">
            <h4 className="grey_bg">{Constants.other}</h4>
            <div className="h5 line-b">
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Avg Workouts / week : ${
                  userSingle?.userGoalWithExerciseDetails?.[0]
                    ?.averageWorkout || 0
                }`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Last Active :  ${dayjs(userSingle?.lastActive).format(
                  Constants.dateFormat
                )} at ${dayjs(userSingle?.lastActive).format(
                  Constants.timeFormat
                )}`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Custom Workout : ${
                  userSingle?.customWorkouts?.length > 0
                    ? userSingle?.customWorkouts
                        ?.slice(0, 2)
                        ?.map((elt) => elt?.workoutName)
                        .join(", ")
                    : Constants.na
                }`}</div>
                {userSingle?.customWorkouts?.length > 0 && (
                  <>
                    {loader ? (
                      <img
                        src={Spinner}
                        alt="spinner"
                        width="30px"
                        height="30px"
                      />
                    ) : (
                      <i
                        className="icofont-download"
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={() => downloadCustomWorkout()}
                      ></i>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex">
          <div className="card mt-4 border-shadow w-100 card-clr card-user-details">
            <h4 className="grey_bg">Badges & Challenges</h4>
            <div className="h5 line-b">
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Badges : ${
                  userSingle?.BadgesEarned?.length > 0
                    ? userSingle?.BadgesEarned?.map(
                        (elt) => elt?.BadgeId?.name
                      ).join(", ")
                    : Constants.na
                }`}</div>
              </p>
              <p className="d-flex align-items-center justfy-bw">
                <div>{`Challenges : ${
                  userSingle?.challengeJoined?.length > 0
                    ? userSingle?.challengeJoined
                        ?.map((elt) => elt?.challengeId?.name)
                        .join(", ")
                    : Constants.na
                }`}</div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Analytics />
    </div>
  );
};

export default UserDetail;
