import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const programmeAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.PROGRAMME, data);
  return response;
};

export const programmeEdit = async (data) => {
  const response = await httpServiceInstance.patch(ApiUrl.PROGRAMME, data);
  return response;
};

export const programmeRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.PROGRAMME + `/${id}`
  );
  return response;
};

export const fetchProgrammeDetail = async (id) => {
  const response = await httpServiceInstance.get(ApiUrl.PROGRAMME + `/${id}`);
  return response;
};

export const programme = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.PROGRAMME}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}${
      data?.status ? `&status=${data?.status}` : ""
    }`
  );
  return response;
};

export const dayWorkoutAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.DAY_WORKOUT, data);
  return response;
};

export const dayWorkoutRemove = async (weekId, day) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.DAY_WORKOUT + `/${weekId}/${day}`
  );
  return response;
};

export const dayWorkout = async (id) => {
  const response = await httpServiceInstance.get(`${ApiUrl.DAY_WORKOUT}/${id}`);
  return response;
};

export const usersProgramme = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.PROGRAMME_USER +
      `?programId=${data?.id}${
        data?.skipPagination
          ? `&${Constants.paginationFalse}`
          : `&skip=${data?.skip || 0}&limit=${
              data?.limit || Constants.limitCount
            }`
      }`
  );
  return response;
};
