import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { types } from "../../Redux/Actions/types";
import { deleteConfirmBox } from "../Common/Functions/deleteConfirmBox";
import { Constants } from "../Common/Constants";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import EditListButton from "../Common/Fields/EditListButton";
import DeleteListButton from "../Common/Fields/DeleteListButton";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import ModalPopup from "../Common/UiModel/ModalPopup";
import {
  equipmentDelete,
  equipmentList,
  equipmentUpdate,
} from "../../Redux/Actions";
import EquipmentForm from "./EquipmentForm";
import { Link } from "react-router-dom";
import ToggleListField from "../Common/Fields/ToggleListField";
import responseMethod from "../Common/Functions/responseMethod";
import ReactSelect from "../Common/Fields/ReactSelect";
import usePermissions from "../Common/Functions/usePermissions";

const EquipmentList = () => {
  const dispatch = useDispatch();
  const { equipmentlist, popupmodel } = useSelector((state) => state.Equipment);
  const [equipmentData, setEquipmentData] = useState([]);
  const [search, setSearch] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState(
    Constants.statusOptions?.[0]
  );
  // Permissions
  const addPermission = usePermissions(Constants.permission.EQUIPMENT_ADD);
  const editPermission = usePermissions(Constants.permission.EQUIPMENT_EDIT);
  const deletePermission = usePermissions(
    Constants.permission.EQUIPMENT_DELETE
  );

  useEffect(() => {
    // Equipment list api
    dispatch(equipmentList());
    dispatch({
      type: types.EQUIPMENT_SINGLE,
      payload: [],
    });
  }, []);

  useEffect(() => {
    // Set data for Equipment from store
    setEquipmentData(equipmentlist?.items);
  }, [equipmentlist]);

  // Debounce search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) getData(search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  // Function to hit Equipment list
  const getData = (searchKey) => {
    const data = { search: searchKey || "", status: selectedStatus.value };
    dispatch(equipmentList(data));
  };

  const onDelete = (delete_id) => {
    // Delete Equipment
    deleteConfirmBox().then((result) => {
      if (result.isConfirmed) {
        dispatch(equipmentDelete(delete_id)).then((response) => {
          if (response.error) {
            dispatch({
              type: types.ERROR_ALERT_VISIBLE,
              payload: response.error,
            });
          } else {
            const data = { search, status: selectedStatus.value };
            dispatch(equipmentList(data));
            setPageIndex(1);
            dispatch({ type: types.DELETE_ALERT_VISIBLE });
          }
        });
      }
    });
  };

  // Function to handle edit functionality for Equipment
  const handleEdit = (row) => {
    dispatch({
      type: types.EQUIPMENT_FORM_MODEL,
      payload: {
        msg: Constants.editEquipment,
        show: true,
        add: 0,
      },
    });
    dispatch({
      type: types.EQUIPMENT_SINGLE,
      payload: row,
    });
  };

  const triggerToggle = (rowData) => {
    // Trigger status toggle for news
    const data = {
      id: rowData?._id,
      status:
        rowData?.status === Constants.status.ACTIVE
          ? Constants.status.INACTIVE
          : Constants.status.ACTIVE,
    };
    dispatch(equipmentUpdate(data)).then((response) => {
      const data = { search, status: selectedStatus.value };
      responseMethod(response, dispatch, equipmentList, data);
    });
  };

  // Columns to Equipment Table List
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.name,
      cell: (row) => (
        <Link to="" onClick={() => handleEdit(row)}>
          {row?.name}
        </Link>
      ),
    },
    {
      name: Constants.statuss,
      cell: (row) =>
        row.status === Constants.status.ACTIVE
          ? Constants.published
          : Constants.notPublished,
    },
    {
      name: Constants.createdDate,
      cell: (row) => dayjs(row?.createdAt).format(Constants.dateFormat),
    },
    {
      name: Constants.createdTime,
      cell: (row) => dayjs(row?.createdAt).format(Constants.timeFormat),
    },
    // Dropdown to edit, delete Equipment
    {
      name: Constants.actions,
      cell: (row) => (
        <div className="switch-tn2 position-relative btn-h">
          <div className="remove-drop-ic">
            <Dropdown className="dropdown user-profilem">
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0 margin-b"
                href="#!"
                role="button"
              >
                <i
                  className="fa fa-ellipsis-h ellips-t cursor-pointer"
                  aria-hidden="true"
                ></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu rounded-lg shadow  btn-lists dropdown-animation dropdown-menu-end  mt-1 ">
                <div className="outer-dot2 btn-list2">
                  {editPermission && (
                    <>
                      <div className="list-group">
                        <EditListButton
                          onClick={() => {
                            handleEdit(row);
                          }}
                        />
                      </div>
                      <div className="list-group">
                        <ToggleListField
                          label={Constants.statusKey}
                          onChange={() => {
                            triggerToggle(row);
                          }}
                          checked={row.status === Constants.status.ACTIVE}
                        />
                      </div>
                    </>
                  )}
                  {deletePermission && (
                    <div className="list-group">
                      <DeleteListButton
                        onClick={() => {
                          onDelete(row._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
    },
  ];

  // Delete the action if user does not have permission
  if (!editPermission && !deletePermission) {
    const index = columns.findIndex((elt) => elt.name === Constants.actions);
    if (index !== -1) columns.splice(index, 1);
  }

  // Pagination function
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      search,
      status: selectedStatus.value,
    };
    dispatch(equipmentList(data));
  };

  const handleStatus = (status) => {
    setSelectedStatus(status);
    const data = {
      search,
      status: status?.value,
    };
    setPageIndex(1);
    dispatch(equipmentList(data));
  };

  const onResetFilter = () => {
    setSearch("");
    setSelectedStatus(Constants.statusOptions?.[0]);
    setPageIndex(1);
    dispatch(equipmentList());
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader
          pagetitle={Constants.equipmentList}
          buttonLink={addPermission}
          onClick={(e) => {
            e.preventDefault();
            dispatch({
              type: types.EQUIPMENT_FORM_MODEL,
              payload: { msg: Constants.addEquipment, show: true },
            });
          }}
        />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div className="col-md-2">
          <ReactSelect
            label={`${Constants.statuss}`}
            required={false}
            options={Constants.statusOptions}
            onChange={(selected) => handleStatus(selected)}
            value={selectedStatus}
          />
        </div>
        <div className="col-md-2">
          <label className="form-label">{Constants.search}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByName}
            onChange={(event) => {
              setSearch(event.target.value);
              setPageIndex(1);
              if (event.target.value === "") {
                setSearch("");
                getData("");
              }
            }}
          ></input>
        </div>
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={equipmentData}
        defaultSortFieldId
        highlightOnHover
      />
      {equipmentlist?.totalCount > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={equipmentlist?.totalCount}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
          pageIndex={pageIndex - 1}
        />
      )}
      <ModalPopup
        show={popupmodel}
        onHide={() => {
          dispatch({ type: types.EQUIPMENT_SINGLE, payload: "" });
          dispatch({ type: types.EQUIPMENT_FORM_MODEL, payload: "" });
        }}
        dialogClassName={"modal-lg"}
        title={popupmodel.msg}
        component={<EquipmentForm />}
      />
    </div>
  );
};

export default EquipmentList;
