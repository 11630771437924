import { Constants } from "./Constants";

// Sidebar menu when user is logged in
export const Menu = [
  {
    name: Constants.dashboard,
    routerLink: [Constants.dashboardKey],
    iconClass: "icofont-home fs-5",
    children: [],
  },
  {
    name: Constants.badges,
    routerLink: [Constants.badgesKey],
    iconClass: "icofont-badge fs-5",
    children: [],
  },
  {
    name: Constants.bodyParts,
    routerLink: [""],
    iconClass: "icofont-gym-alt-1 fs-5",
    children: [
      {
        name: Constants.bodyGroup,
        routerLink: [Constants.bodyGroupKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.exercisePosition,
        routerLink: [Constants.exercisePositionKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.joint,
        routerLink: [Constants.jointKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.muscle,
        routerLink: [Constants.muscleKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.primaryJoint,
        routerLink: [Constants.primaryJointKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.primaryMuscle,
        routerLink: [Constants.primaryMuscleKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.synergistMuscle,
        routerLink: [Constants.synergistMuscleKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.exerciseCategory,
    routerLink: [Constants.exerciseCategoryKey],
    iconClass: "icofont-cube fs-5",
    children: [],
  },
  {
    name: Constants.challenge,
    routerLink: [Constants.challengeKey],
    iconClass: "icofont-chart-histogram fs-5",
    children: [],
  },
  {
    name: Constants.customer,
    routerLink: [Constants.usersKey],
    iconClass: "icofont-users-alt-3 fs-5",
    children: [],
  },
  {
    name: Constants.cms,
    routerLink: [""],
    iconClass: "icofont-ui-settings fs-5",
    children: [
      {
        name: Constants.dashboardImage,
        routerLink: [Constants.cmsDashboardKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.faq,
        routerLink: [Constants.faqKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.goalTiming,
        routerLink: [Constants.goalTimingKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.privacyPolicy,
        routerLink: [Constants.privacyPolicyKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.userAgreement,
        routerLink: [Constants.userAgreementKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.equipment,
    routerLink: [Constants.equipmentKey],
    iconClass: "icofont-dumbbell fs-5",
    children: [],
  },
  {
    name: Constants.exercise,
    routerLink: [Constants.exerciseKey],
    iconClass: "icofont-gym-alt-2 fs-5",
    children: [],
  },
  {
    name: Constants.exerciseGoal,
    routerLink: [Constants.exerciseGoalKey],
    iconClass: "fa fa-star-o fs-5",
    children: [],
  },
  {
    name: Constants.goals,
    routerLink: [Constants.goalKey],
    iconClass: "icofont-goal fs-5",
    children: [],
  },
  {
    name: Constants.mechanics,
    routerLink: [""],
    iconClass: "icofont-help-robot fs-5",
    children: [
      {
        name: Constants.mechanicsJoint,
        routerLink: [Constants.mechanicsJointKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.movementPattern,
        routerLink: [Constants.movementPatternKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.force,
        routerLink: [Constants.forceKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.notification,
    routerLink: [Constants.notificationKey],
    iconClass: "icofont-bell-alt fs-5",
    children: [],
  },
  {
    name: Constants.programme,
    routerLink: [Constants.programmeKey],
    iconClass: "icofont-file-presentation fs-5",
    children: [],
  },
  {
    name: Constants.reports,
    routerLink: [""],
    iconClass: "icofont-dashboard-web fs-5",
    children: [
      {
        name: Constants.frequentUsers,
        routerLink: [Constants.frequentUsersKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.workoutAnalytics,
        routerLink: [Constants.workoutAnalyticsKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.workoutSessionSplit,
        routerLink: [Constants.workoutSessionSplitKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.strengthAssessment,
        routerLink: [Constants.strengthAssessmentKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.exerciseCategory,
        routerLink: [Constants.bodyPartCateKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.roleMgt,
    routerLink: [""],
    iconClass: "icofont-businessman fs-5",
    children: [
      {
        name: Constants.manageUsers,
        routerLink: [Constants.manageUsersKey],
        iconClass: "",
        children: [],
      },
      {
        name: Constants.manageRoles,
        routerLink: [Constants.manageRolesKey],
        iconClass: "",
        children: [],
      },
    ],
  },
  {
    name: Constants.tutorial,
    routerLink: [Constants.tutorialKey],
    iconClass: "icofont-learn fs-5",
    children: [],
  },
  {
    name: Constants.workout,
    routerLink: [Constants.workoutKey],
    iconClass: "icofont-dumbbells fs-5",
    children: [],
  },
  {
    name: Constants.signout,
    routerLink: [Constants.signout],
    iconClass: "icofont-sign-out fs-5",
    children: [],
  },
];
