import {
  frequentUsers,
  getAssessExercises,
  getWorkoutAnalytics,
} from "../../services/report.service";
import { types } from "./types";

export const frequentUsersList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await frequentUsers(data);
  if (response?.status) {
    const result = response?.data;
    dispatch({ type: types.FREQUENT_USER_LIST, payload: result?.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.FREQUENT_USER_LIST,
      payload: [],
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const fetchWorkoutAnalytics = (days) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await getWorkoutAnalytics(days);
  if (response?.status) {
    const result = response?.data?.response;
    dispatch({ type: types.WORKOUT_ANALYTICS, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.WORKOUT_ANALYTICS,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const fetchAssessExercises = () => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await getAssessExercises();
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
