import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Constants } from "../Common/Constants";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Quarterly from "./Quarterly";
import Yearly from "./Yearly";
import TabsBootstrap from "../Common/UiModel/TabsBootstrap";
import { useDispatch } from "react-redux";
import {
  exerciseCategoryList,
  fetchAssessExercises,
  getUserDetail,
} from "../../Redux/Actions";
import { useSelector } from "react-redux";
import ReactSelect from "../Common/Fields/ReactSelect";

const Analytics = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { exerciseCategorylist } = useSelector(
    (state) => state.ExerciseCategory
  );
  const [bodyPart, setBodyPart] = useState([]);
  const [bodyPartData, setBodyPartData] = useState([]);
  const [assessmentExer, setAssessmentExer] = useState(
    Constants.statusOptions?.[0]
  );
  const [assessmentExerData, setAssessmentExerData] = useState([]);

  const tabsList = [
    {
      eventKey: Constants.analyticsWeek,
      title: Constants.analyticsSevenDays,
      component: <Weekly />,
    },
    {
      eventKey: Constants.analyticsMonth,
      title: Constants.analyticsThirtyDays,
      component: <Monthly />,
    },
    {
      eventKey: Constants.analyticsThreeMonths,
      title: Constants.analyticsNinetyDays,
      component: <Quarterly />,
    },
    {
      eventKey: Constants.analyticsYear,
      title: Constants.analyticsOneYear,
      component: <Yearly />,
    },
  ];

  const [activetab, setActiveTab] = useState(tabsList?.[0]?.eventKey);

  useEffect(() => {
    dispatch(
      exerciseCategoryList({
        skipPagination: true,
        status: Constants.status.ACTIVE,
      })
    );
    getAssessExer();
  }, []);

  const getAssessExer = async () => {
    const resp = await dispatch(fetchAssessExercises());
    if (resp?.data?.length > 0) {
      const data = [Constants.statusOptions?.[0]];
      resp?.data?.forEach((exer) => {
        data.push({
          value: exer?._id,
          label: exer?.exerciseName,
        });
      });
      setAssessmentExerData(data);
    }
  };

  useEffect(() => {
    const data = {
      id,
      days: activetab,
      bodyPart: bodyPart?.value || null,
      assessExer: assessmentExer?.value || null,
    };
    dispatch(getUserDetail(data));
  }, [activetab, bodyPart?.value, assessmentExer]);

  useEffect(() => {
    if (exerciseCategorylist?.exerciseCategory?.length > 0) {
      const data = exerciseCategorylist?.exerciseCategory?.map((item) => ({
        value: item?._id,
        label: item?.title,
      }));
      setBodyPartData(data);
      setBodyPart(data?.[0]);
    }
  }, [exerciseCategorylist]);

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  const onBodyPartChnage = (e) => {
    setBodyPart(e);
  };

  return (
    <div className="row catalog_box al_catalog_box mt-4">
      <div className="col-md-12 mb-4 order-list-view">
        <div className="col-md-2 filter-tabs mx-2">
          <ReactSelect
            label={`${Constants.strengthAssessment}`}
            options={assessmentExerData}
            onChange={(e) => setAssessmentExer(e)}
            value={assessmentExer}
          />
        </div>
        <div className="col-md-2 filter-tabs">
          <ReactSelect
            label={`${Constants.exerciseCategory}`}
            options={bodyPartData}
            onChange={(e) => onBodyPartChnage(e)}
            value={bodyPart}
          />
        </div>
        {tabsList.length > 0 && (
          <TabsBootstrap
            defaultActiveKey={activetab}
            onSelect={(index) => {
              setActiveTab(index);
            }}
            activeKey={activetab}
            handleTabSelect={handleTabSelect}
            options={tabsList}
          />
        )}
      </div>
    </div>
  );
};

export default Analytics;
