import { types } from "./types";
import {
  goals,
  goalsAdd,
  goalsEdit,
  goalsRemove,
} from "../../services/goal.service";

export const goalsInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goalsAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const goalsList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goals(data);
  if (response?.status) {
    const result = response?.data?.goalsDetails;
    dispatch({ type: types.GOALS_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.GOALS_LIST, payload: { error: response.error } });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const goalsUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goalsEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const goalsDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goalsRemove(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
