import React from "react";
import AreaGraph from "../Common/Graphs/AreaGraph";
import LineGraph from "../Common/Graphs/LineGraph";
import { Constants } from "../Common/Constants";
import { useSelector } from "react-redux";
import BarGraph from "../Common/Graphs/BarGraph";

const Weekly = () => {
  const { userSingle } = useSelector((store) => store.Users);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.totalWeightListedByCategory}
          title={Constants.totalWeightLiftedSplit}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.totalRepsByCategory}
          title={Constants.totalRepsSplit}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.strengthAssessment}
          title={Constants.strengthAssessment}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <LineGraph
          data={userSingle?.totalWeight}
          title={Constants.totalWeightLifted}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <LineGraph
          data={userSingle?.totalReps}
          title={Constants.totalReps}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <LineGraph
          data={userSingle?.caloriesBurnt}
          title={Constants.caloriesBurnt}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={userSingle?.totalTimeUnderTension}
          title={Constants.timeUnderTension}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={userSingle?.progress}
          title={Constants.progress}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <BarGraph
          data={userSingle?.totalQuickTrainWorkout}
          title={Constants.quickTrainWorkout}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraph
          data={userSingle?.totalWorkoutCreated}
          title={Constants.totalWorkouts}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Weekly;
