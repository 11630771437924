import React from "react";
import { Constants } from "../Common/Constants";
import AreaGraphMonthly from "../Common/Graphs/AreaGraphMonthyl";
import { useSelector } from "react-redux";

const Quarterly = () => {
  const { userSingle } = useSelector((store) => store.Users);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalWeightListedByCategory}
          title={Constants.totalWeightLiftedSplit}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalRepsByCategory}
          title={Constants.totalRepsSplit}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.strengthAssessment}
          title={Constants.strengthAssessment}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalWeight}
          title={Constants.totalWeightLifted}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalReps}
          title={Constants.totalReps}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.caloriesBurnt}
          title={Constants.caloriesBurnt}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalTimeUnderTension}
          title={Constants.timeUnderTension}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.progress}
          title={Constants.progress}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalQuickTrainWorkout}
          title={Constants.quickTrainWorkout}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={userSingle?.totalWorkoutCreated}
          title={Constants.totalWorkouts}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Quarterly;
