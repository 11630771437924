import { ApiUrl } from "../../components/Common/ApiUrl";
import { Constants } from "../../components/Common/Constants";
import httpServiceInstance from "../../helper/HttpService";

export const mechanicsJointAdd = async (data) => {
  const response = await httpServiceInstance.post(ApiUrl.MECHANICS_JOINT, data);
  return response;
};

export const mechanicsJointEdit = async (data) => {
  const response = await httpServiceInstance.patch(
    ApiUrl.MECHANICS_JOINT,
    data
  );
  return response;
};

export const mechanicsJointRemove = async (id) => {
  const response = await httpServiceInstance.delete(
    ApiUrl.MECHANICS_JOINT + `/${id}`
  );
  return response;
};

export const mechanicsJoint = async (data) => {
  const response = await httpServiceInstance.get(
    `${ApiUrl.MECHANICS_JOINT}${
      data?.skipPagination
        ? `?${Constants.paginationFalse}`
        : `?skip=${data?.skip || 0}&limit=${
            data?.limit || Constants.limitCount
          }`
    }${data && data?.search ? `&search=${data?.search}` : ""}${
      data?.status ? `&status=${data?.status}` : ""
    }`
  );
  return response;
};
