import React from "react";
import { useSelector } from "react-redux";
import AreaGraphMonthly from "../../Common/Graphs/AreaGraphMonthyl";
import { Constants } from "../../Common/Constants";

const Monthly = () => {
  const { workoutanalytics } = useSelector((store) => store.Reports);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.genderWisetotalRepsExerciseCategoryId}
          title={Constants.totalReps}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={workoutanalytics?.genderWisetotalWeightLiftedExerciseCategoryId}
          title={Constants.totalWeightLifted}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Monthly;
