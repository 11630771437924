import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Constants } from "../../Common/Constants";
import { fetchWorkoutAnalytics } from "../../../Redux/Actions";
import TabsBootstrap from "../../Common/UiModel/TabsBootstrap";
import Yearly from "./Yearly";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Quarterly from "./Quarterly";
import ReactSelect from "../../Common/Fields/ReactSelect";
import ExcelIcon from "../../../assets/images/excel.png";
import axios from "axios";
import { ApiUrl } from "../../Common/ApiUrl";
import usePermissions from "../../Common/Functions/usePermissions";
import Spinner from "../../../assets/images/Spinner.gif";

const WorkoutAnalytics = () => {
  const tabsList = [
    {
      eventKey: Constants.analyticsWeek,
      title: Constants.analyticsSevenDays,
      component: <Weekly />,
    },
    {
      eventKey: Constants.analyticsMonth,
      title: Constants.analyticsThirtyDays,
      component: <Monthly />,
    },
    {
      eventKey: Constants.analyticsThreeMonths,
      title: Constants.analyticsNinetyDays,
      component: <Quarterly />,
    },
    {
      eventKey: Constants.analyticsYear,
      title: Constants.analyticsOneYear,
      component: <Yearly />,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activetab, setActiveTab] = useState(tabsList?.[0]?.eventKey);
  const [selectedGender, setSelectedGender] = useState(
    Constants.genderOptions?.[0]
  );
  const [loader, setLoader] = useState(false);
  // Permissions
  const viewPermission = usePermissions(
    Constants.permission.DOWNLOAD_REPORT_VIEW
  );

  useEffect(() => {
    const data = { days: activetab, gender: selectedGender.value };
    dispatch(fetchWorkoutAnalytics(data));
  }, [activetab, selectedGender]);

  useEffect(() => {
    // Set active tab as per URL
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get(Constants.activeTab);
    if (tabParam && tabsList.map((elt) => elt.eventKey).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location]);

  const handleTabSelect = (key) => {
    // Set URL in correspondance with active tab
    setActiveTab(key);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(Constants.activeTab, key);
    navigate(`?${queryParams.toString()}`);
  };

  const onExportExcel = async () => {
    setLoader(true);
    const data = { days: activetab, gender: selectedGender.value };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${ApiUrl.WORKOUT_REPORT_EXCEL}?days=${
          data?.days
        }${data?.gender ? `&gender=${data?.gender}` : ""}`,
        {
          baseURL: `${process.env.REACT_APP_API_URL}`,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              Constants.accessToken
            )}`,
          },
        }
      );
      setLoader(false);
      // Create a Blob from the data and generate a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Reports.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setLoader(false);
      console.error("Error downloading the Excel file:", error);
    }
  };

  return (
    <div className="container-xxl">
      <div className="catalog_box al_catalog_box mt-4">
        <div className="row row_end order-list-view">
          <div className="col-md-2 filter-tabs">
            <ReactSelect
              label={Constants.gender}
              options={Constants.genderOptions}
              onChange={(e) => setSelectedGender(e)}
              value={selectedGender}
            />
          </div>
          {viewPermission && (
            <>
              {loader ? (
                <div className="col-md-1 filter-tabs">
                  <img src={Spinner} alt="spinner" width="40px" height="40px" />
                </div>
              ) : (
                <div className="col-md-1 filter-tabs">
                  <div className="btn-hover">
                    <Link
                      onClick={() => {
                        onExportExcel();
                      }}
                      title=""
                      className="btn btn-primary text-uppercase"
                    >
                      <img
                        src={ExcelIcon}
                        alt="excel-icon"
                        className="img-excel"
                      />
                      {Constants.export}
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <TabsBootstrap
          defaultActiveKey={activetab}
          onSelect={(index) => {
            setActiveTab(index);
          }}
          activeKey={activetab}
          handleTabSelect={handleTabSelect}
          options={tabsList}
        />
      </div>
    </div>
  );
};

export default WorkoutAnalytics;
