export const ApiUrl = {
  BADGE: "admin/master/badges",
  BADGE_USER: "admin/userAnalytics/badgesJoined",
  BODY_GROUP: "/admin/master/BodyGroups",
  CHALLENGE: "admin/master/Challenge",
  CHALLENGE_USER: "admin/userAnalytics/challengesJoined",
  CONFIGURATION: "admin/master/CMS",
  DASHBOARD: "admin/dashboard",
  DAY_WORKOUT: "admin/master/week/day",
  EQUIPMENT: "admin/master/Equipment",
  EXERCISE: "admin/exercise/ExerciseDetails",
  EXERCISE_CATEGORY: "admin/exercise/ExerciseCategory",
  EXERCISE_GOAL: "admin/master/ExerciseGoal",
  EXERCISE_POSITION: "admin/master/ExercisePosition",
  FAQ: "admin/master/faqs",
  FORCE: "admin/master/Force",
  FREQUENT_USERS: "admin/reports/userList",
  GOAL: "admin/goal",
  GOAL_TIMING: "admin/master/goalTiming",
  IMAGE_URL: "admin/master/image-uploads",
  JOINT: "admin/master/AvoidForJoints",
  LOGIN: "auth/adminregister",
  LOGOUT: "auth/adminlogout",
  MECHANICS_JOINT: "admin/master/Mechanics",
  MOVEMENT_PATTERN: "admin/master/MovementPatterns",
  MUSCLE: "admin/master/AvoidForMuscle",
  NOTIFICATION: "admin/notification",
  PERMISSION: "admin/permission",
  PRIMARY_JOINT: "admin/master/PrimaryJoints",
  PRIMARY_MUSCLE: "admin/master/Primarymuscles",
  PROGRAMME: "admin/master/Program",
  PROGRAMME_USER: "admin/userAnalytics/ProgramJoined",
  ROLES: "admin/roles",
  ROLE_USERS: "auth/adminUsers",
  STRENGTH_ASSESS_EXERCISE: "admin/reports/strengthAssessmentExercises",
  SYNERGIST_MUSCLE: "admin/master/Synergistmuscle",
  TUTORIAL: "admin/master/Tutorial",
  USER_DETAIL: "admin/userAnalytics/reports",
  USER_LIST: "admin/user/list",
  USERS: "users",
  USER_CUSTOM_WORKOUT: "admin/userAnalytics/customWorkoutExport",
  WORKOUT: "admin/master/workoutProgram",
  WORKOUT_ANALYTICS: "admin/reports/workoutAnalytics",
  WORKOUT_EXERCISE: "admin/master/workoutProgram/Exercise",
  WORKOUT_REPORT_EXCEL: "admin/reports/export",
  WORKOUT_SUPERSET_EXERCISE: "admin/master/workoutProgramsuperset/Exercise",
};
