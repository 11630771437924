import React from "react";
import { Constants } from "../Common/Constants";
import { useSelector } from "react-redux";
import AreaGraphMonthly from "../Common/Graphs/AreaGraphMonthyl";

const Monthly = () => {
  const { dashboardData } = useSelector((store) => store.Dashboard);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={dashboardData?.registeredUsermonth}
          title={Constants.totalRegisteredUsers}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={dashboardData?.totalActiveUserMonth}
          title={Constants.totalActiveUsers}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={dashboardData?.activeMaleUsersMonth}
          title={Constants.totalActiveUsersMale}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={dashboardData?.activeFeMaleUsersMonth}
          title={Constants.totalActiveUsersFemale}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={dashboardData?.lastmonthWorkoutSession}
          title={Constants.totalWorkoutSessions}
          format=""
        />
      </div>
      <div id="chart" className="col-md-4">
        <AreaGraphMonthly
          data={dashboardData?.averageWorkoutsDurationPermonth}
          title={Constants.totalWorkoutSessionDuration}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Monthly;
