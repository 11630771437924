import { Constants } from "../../Common/Constants";
import { ApiRoutes } from "./ApiRoutes";

// Permissions object list for each tab type (view, edit, add, delete)
export const permissionList = [
  {
    key: Constants.dashboardKey,
    value: Constants.dashboard,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.dashboardView,
  },
  {
    key: Constants.badgesKey,
    value: Constants.badges,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.badgesView,
    api_edit_route: ApiRoutes.badgesEdit,
    api_add_route: ApiRoutes.badgesAdd,
    api_delete_route: ApiRoutes.badgesDelete,
  },
  {
    key: Constants.bodyPartsKey,
    value: Constants.bodyParts,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.bodyPartsView,
    api_edit_route: ApiRoutes.bodyPartsEdit,
    api_add_route: ApiRoutes.bodyPartsAdd,
    api_delete_route: ApiRoutes.bodyPartsDelete,
  },
  {
    key: Constants.exerciseCategoryKey,
    value: Constants.exerciseCategory,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.exerCategoryView,
    api_edit_route: ApiRoutes.exerCategoryEdit,
    api_add_route: ApiRoutes.exerCategoryAdd,
    api_delete_route: ApiRoutes.exerCategoryDelete,
  },
  {
    key: Constants.challengeKey,
    value: Constants.challenge,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.challengeView,
    api_edit_route: ApiRoutes.challengeEdit,
    api_add_route: ApiRoutes.challengeAdd,
    api_delete_route: ApiRoutes.challengeDelete,
  },
  {
    key: Constants.usersKey,
    value: Constants.customer,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.usersView,
  },
  {
    key: Constants.cmsKey,
    value: Constants.cms,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.cmsView,
    api_edit_route: ApiRoutes.cmsEdit,
    api_add_route: ApiRoutes.cmsAdd,
    api_delete_route: ApiRoutes.cmsDelete,
  },
  {
    key: Constants.equipmentKey,
    value: Constants.equipment,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.equipmentView,
    api_edit_route: ApiRoutes.equipmentEdit,
    api_add_route: ApiRoutes.equipmentAdd,
    api_delete_route: ApiRoutes.equipmentDelete,
  },
  {
    key: Constants.exerciseKey,
    value: Constants.exercise,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.exerView,
    api_edit_route: ApiRoutes.exerEdit,
    api_add_route: ApiRoutes.exerAdd,
    api_delete_route: ApiRoutes.exerDelete,
  },
  {
    key: Constants.exerciseGoalKey,
    value: Constants.exerciseGoal,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.exerGoalView,
    api_edit_route: ApiRoutes.exerGoalEdit,
    api_add_route: ApiRoutes.exerGoalAdd,
    api_delete_route: ApiRoutes.exerGoalDelete,
  },
  {
    key: Constants.goalKey,
    value: Constants.goals,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.goalView,
    api_edit_route: ApiRoutes.goalEdit,
    api_add_route: ApiRoutes.goalAdd,
    api_delete_route: ApiRoutes.goalDelete,
  },
  {
    key: Constants.mechanicsKey,
    value: Constants.mechanics,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.mechanicsView,
    api_edit_route: ApiRoutes.mechanicsEdit,
    api_add_route: ApiRoutes.mechanicsAdd,
    api_delete_route: ApiRoutes.mechanicsDelete,
  },
  {
    key: Constants.notificationKey,
    value: Constants.notification,
    view: false,
    add: false,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.notifiView,
    api_add_route: ApiRoutes.notifiAdd,
  },
  {
    key: Constants.programmeKey,
    value: Constants.programme,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.programmeView,
    api_edit_route: ApiRoutes.programmeEdit,
    api_add_route: ApiRoutes.programmeAdd,
    api_delete_route: ApiRoutes.programmeDelete,
  },
  {
    key: Constants.reportsKey,
    value: Constants.reports,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.reportView,
  },
  {
    key: Constants.downloadReportKey,
    value: Constants.downloadReport,
    view: false,
    add: Constants.disabled,
    edit: Constants.disabled,
    delete: Constants.disabled,
    api_view_route: ApiRoutes.reportView,
  },
  {
    key: Constants.roleMgtKey,
    value: Constants.roleMgt,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.roleMgtView,
    api_edit_route: ApiRoutes.roleMgtEdit,
    api_add_route: ApiRoutes.roleMgtAdd,
    api_delete_route: ApiRoutes.roleMgtDelete,
  },
  {
    key: Constants.tutorialKey,
    value: Constants.tutorial,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.tutorialView,
    api_edit_route: ApiRoutes.tutorialEdit,
    api_add_route: ApiRoutes.tutorialAdd,
    api_delete_route: ApiRoutes.tutorialDelete,
  },
  {
    key: Constants.workoutKey,
    value: Constants.workout,
    view: false,
    add: false,
    edit: false,
    delete: false,
    api_view_route: ApiRoutes.workoutView,
    api_edit_route: ApiRoutes.workoutView,
    api_add_route: ApiRoutes.workoutView,
    api_delete_route: ApiRoutes.workoutView,
  },
];
