import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import httpServiceInstance from "../helper/HttpService";

export const frequentUsers = async (data) => {
  const url = `${ApiUrl.FREQUENT_USERS}${
    data?.skipPagination
      ? `?${Constants.paginationFalse}`
      : `?skip=${data?.skip || 0}&limit=${data?.limit || Constants.limitCount}`
  }${data && data?.search ? `&search=${data?.search}` : ""}&ascending=${
    data?.ascending || -1
  }${data?.gender ? `&gender=${data?.gender}` : ""}`;
  const response = await httpServiceInstance.get(url);
  return response;
};

export const getWorkoutAnalytics = async (data) => {
  const response = await httpServiceInstance.get(
    ApiUrl.WORKOUT_ANALYTICS +
      `?days=${data?.days}${data?.mode ? `&mode=${data?.mode}` : ""}${
        data?.gender ? `&gender=${data?.gender}` : ""
      }${data?.bodyPart ? `&exerciseCategoryId=${data?.bodyPart}` : ""}${
        data?.assessExer ? `&exerciseId=${data?.assessExer}` : ""
      }`
  );
  return response;
};

export const getAssessExercises = async () => {
  const response = await httpServiceInstance.get(
    ApiUrl.STRENGTH_ASSESS_EXERCISE
  );
  return response;
};
