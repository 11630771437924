import {
  addConfigurations,
  faq,
  faqAdd,
  faqEdit,
  faqRemove,
  getConfigurations,
  goalTiming,
  goalTimingAdd,
  goalTimingEdit,
} from "../../services/cms.service";
import { types } from "./types";

export const goalTimingInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goalTimingAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const goalTimingList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goalTiming(data);
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const goalTimingUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await goalTimingEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const getAdminConfigurations = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await getConfigurations(data);
  if (response?.status) {
    const res = response.data;
    dispatch({ type: types.ADMIN_CONFIGURATION_LIST, payload: res });
    dispatch({ type: types.LOADER_HIDE });
    return { data: res };
  } else {
    dispatch({
      type: types.ADMIN_CONFIGURATION_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const addAdminConfigurations = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await addConfigurations(data);
  if (response?.status) {
    const res = response.data;
    dispatch({ type: types.ADMIN_CONFIGURATION_ADD, payload: res });
    dispatch({ type: types.LOADER_HIDE });
    return { data: res };
  } else {
    dispatch({
      type: types.ADMIN_CONFIGURATION_ADD,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const faqInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await faqAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const faqList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await faq(data);
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.FAQ_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.FAQ_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const faqUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await faqEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const faqDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await faqRemove(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
