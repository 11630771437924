import {
  fetchWorkoutDetail,
  workout,
  workoutAdd,
  workoutEdit,
  workoutExerciseAdd,
  workoutExerciseEdit,
  workoutExerciseRemove,
  workoutExerciseSupersetAdd,
  workoutRemove,
} from "../../services/workout.service";
import { types } from "./types";

export const workoutInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workout(data);
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.WORKOUT_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.WORKOUT_LIST, payload: { error: response.error } });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutRemove(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutExerciseInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutExerciseAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutExerciseSupersetInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutExerciseSupersetAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const getWorkoutDetail = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await fetchWorkoutDetail(data);
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.WORKOUT_DETAIL, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.WORKOUT_DETAIL,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutExerciseUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutExerciseEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const workoutExerciseDelete = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await workoutExerciseRemove(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
