import HttpService from "../helper/HttpService";
import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";

export const loginUser = async (data) => {
  const response = await HttpService.post(ApiUrl.LOGIN, data);
  return response;
};

export const userPermission = async () => {
  const roleId = localStorage.getItem(Constants.roleId);
  if (roleId !== "null" && !!roleId) {
    const url = ApiUrl.PERMISSION + `?role=${roleId}`;
    const response = await HttpService.get(url);
    return response;
  }
  return null;
};

export const logoutUser = async () => {
  const response = await HttpService.get(ApiUrl.LOGOUT);
  return response;
};
