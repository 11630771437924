import React from "react";
import { Constants } from "../Common/Constants";
import Graph from "./Graph";
import { RoutesUrl } from "../Common/RoutesUrl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RecentSignups from "./RecentSignups";

const Dashboard = () => {
  const navigate = useNavigate();
  const { dashboardData } = useSelector((store) => store.Dashboard);
  const { permissionsArr } = useSelector((state) => state.Auth);
  const roleType = localStorage.getItem(Constants.roleType);

  // Data to be shown in tabs
  const adminData = [
    {
      title: Constants.totalRegisteredUsers,
      to: RoutesUrl.users,
      value: dashboardData?.registeredUserCount || 0,
      iconClass: "icofont-users fs-3 color-lightblue",
      cardBg: "bg-light",
      permission: Constants.permission.CUSTOMER_VIEW,
    },
    {
      title: "Average Age of Users",
      to: RoutesUrl.users,
      value:
        `${
          dashboardData?.avgAge
            ? `${Math.round(dashboardData?.avgAge)} years`
            : ""
        } ` || 0,
      iconClass: "icofont-businessman fs-3 color-lavender-purple",
      cardBg: "bg-light",
      permission: Constants.permission.CUSTOMER_VIEW,
    },
    {
      title: Constants.exercises,
      to: RoutesUrl.exercise,
      value: dashboardData?.totalExerciseVideos || 0,
      iconClass: "icofont-gym-alt-2 fs-3 color-red",
      cardBg: "bg-light",
      permission: Constants.permission.EXERCISE_VIEW,
    },
    {
      title: Constants.programs,
      to: RoutesUrl.programme,
      value: dashboardData?.TotalPrograms || 0,
      iconClass: "icofont-file-presentation fs-3 color-green",
      cardBg: "bg-light",
      permission: Constants.permission.PROGRAMME_VIEW,
    },
  ];

  return (
    <div className="container-xxl mb-4">
      <h2 className="dashboard-head">{Constants.dashboard}</h2>
      <div className="row">
        {adminData &&
          adminData.map((item, i) => {
            return (
              <div
                key={"todaydata" + i}
                className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3"
              >
                <div
                  onClick={() =>
                    item?.to &&
                    (roleType === Constants.defaultAdmin ||
                      permissionsArr?.[item?.permission])
                      ? navigate(item.to)
                      : ""
                  }
                  className={`${
                    item?.to &&
                    (roleType === Constants.defaultAdmin ||
                      permissionsArr?.[item?.permission])
                      ? "cursor-pointer"
                      : ""
                  }`}
                >
                  <div className="card card-m list-view">
                    <div className="card-body row d-flex flex-wrap align-items-center justify-content-between add_food_item">
                      <div className="left-info col-md-10 col-8">
                        {item.title}
                        <div>
                          <span className="fs-6 fw-bold me-2">
                            {item.value}
                          </span>
                        </div>
                      </div>
                      <div className="right-icon col-md-2 col-4">
                        <i className={`${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              // <div className="col-md-3 col-sm-6" key={i}>
              //   <div className=" card card-m list-view">
              //     <div className=" flex_up">
              //       <div className=" left_flex_up">
              //         <h5>Total Signups</h5>
              //         <h6>20</h6>
              //       </div>
              //       <div className=" right_graph">
              //         <img src={increase_graph} className="graph_ic" />
              //         <h5 className="up_clr_green">+20%</h5>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            );
          })}
      </div>
      <Graph />
      <RecentSignups />
    </div>
  );
};

export default Dashboard;
