import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Constants } from "../Common/Constants";
import { types } from "../../Redux/Actions/types";
import { sendNotification } from "../../Redux/Actions/Notification";
import TextField from "../Common/Fields/TextField";
import TextArea from "../Common/Fields/TextArea";
import ReactSelect from "../Common/Fields/ReactSelect";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import usePermissions from "../Common/Functions/usePermissions";

const NotificationForm = ({ data }) => {
  const today = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const [selectedSetRule, setSelectedSetRule] = useState(
    Constants.setRuleOptions?.[0]
  );
  // Permissions
  const addPermission = usePermissions(Constants.permission.NOTIFI_ADD);

  // Initial values for notification form
  const [initialValues, setInitialValues] = useState({
    message: "",
    subject: "",
    set_rule: Constants.setRuleOptions?.[0]?.value,
    scheduled_date: "",
    scheduled_time: "",
  });

  // Validation for notification form
  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,1000}$/, `${Constants.characterLimitCrossed} (1000)`),
    subject: Yup.string()
      .required(Constants.required)
      .matches(/^.{1,100}$/, `${Constants.characterLimitCrossed} (100)`),
    scheduled_date:
      selectedSetRule?.value === "scheduled"
        ? Yup.string().required(Constants.required)
        : "",
    scheduled_time:
      selectedSetRule?.value === "scheduled"
        ? Yup.string().required(Constants.required)
        : "",
  });

  // set prefilled value for notification form (while viewing the notification)
  useEffect(() => {
    if (data) {
      const editValue = {};
      for (const [key] of Object.entries(initialValues)) {
        if (data[key]) {
          editValue[key] = data[key];
        }
        if (key === "set_rule") {
          const arr = Constants?.setRuleOptions?.find(
            (item) => item.value === data[key]
          );
          setSelectedSetRule(arr);
        }
        setInitialValues(editValue);
      }
    }
  }, [data]);

  return (
    <div className="course-form card">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          // Send notification API
          const submitFunc = sendNotification(values);
          dispatch(submitFunc).then((response) => {
            if (response?.error) {
              dispatch({
                type: types.ERROR_ALERT_VISIBLE,
                payload: response?.error,
              });
            } else {
              // Reset the form values
              resetForm();
              setSelectedSetRule(Constants.setRuleOptions?.[0]);
              dispatch({
                type: types.SUCCESS_ALERT_VISIBLE,
                payload: response?.data?.message,
              });
            }
          });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="row">
            <div className="col-md-12 mb-4">
              <TextField
                type="text"
                name="subject"
                className="form-control"
                errors={touched?.subject ? errors?.subject : undefined}
                touched={touched?.subject}
                label={Constants.title}
                disabled={!!data}
              />
            </div>
            <div className="col-md-12 mb-4">
              <TextArea
                type="text"
                name="message"
                className="form-control"
                errors={touched?.message ? errors?.message : undefined}
                touched={touched?.message}
                label={Constants.message}
                disabled={!!data}
              />
            </div>
            <div className="col-md-4 mb-4">
              <ReactSelect
                label={Constants.setRule}
                required={true}
                options={Constants.setRuleOptions}
                value={selectedSetRule}
                onChange={(selected) => {
                  setFieldValue("set_rule", selected.value);
                  setSelectedSetRule(selected);
                }}
                isDisabled={!!data}
              />
            </div>
            {selectedSetRule?.value === "scheduled" && (
              <>
                <div className="col-md-4 mb-4">
                  <TextField
                    type="date"
                    name="scheduled_date"
                    className="form-control"
                    min={today}
                    errors={
                      touched?.scheduled_date
                        ? errors?.scheduled_date
                        : undefined
                    }
                    touched={touched?.scheduled_date}
                    label={Constants.selectDate}
                    disabled={!!data}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    type="time"
                    name="scheduled_time"
                    className="form-control"
                    errors={
                      touched?.scheduled_time
                        ? errors?.scheduled_time
                        : undefined
                    }
                    touched={touched?.scheduled_time}
                    label={Constants.selectTime}
                    disabled={!!data}
                  />
                </div>
              </>
            )}
            {addPermission && (
              <ButtonCustom label={Constants.submit} type="submit" />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NotificationForm;
