import React, { useEffect, useState } from "react";
import RoleList from "./RoleList";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import { permissionList } from "./PermissionsList";
import { useDispatch, useSelector } from "react-redux";
import { addRolePermission, fetchRolePermission } from "../../../Redux/Actions";
import { types } from "../../../Redux/Actions/types";
import { ApiRoutes } from "./ApiRoutes";
import usePermissions from "../../Common/Functions/usePermissions";

const ManageRoles = () => {
  const [sequence, setSequence] = useState(permissionList);
  const [selectedRole, setSelectedRole] = useState(false);
  const dispatch = useDispatch();
  const { permissionlist } = useSelector((state) => state.Roles);
  // Permissions
  const addPermission = usePermissions(Constants.permission.ROLE_MGT_ADD);
  const editPermission = usePermissions(Constants.permission.ROLE_MGT_EDIT);

  // get role permission
  useEffect(() => {
    if (selectedRole?._id) {
      dispatch(fetchRolePermission(selectedRole?._id));
    }
  }, [selectedRole]);

  // Convert the permission API data to permission object
  useEffect(() => {
    if (permissionlist?.permissions?.length > 0) {
      const obj = {};
      permissionlist?.permissions.forEach(
        (field) => (obj[field?.key] = field.is_enabled)
      );
      setSequence([
        {
          key: Constants.dashboardKey,
          value: Constants.dashboard,
          view: obj[Constants.permission.DASHBOARD_VIEW],
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.dashboardView,
        },
        {
          key: Constants.badgesKey,
          value: Constants.badges,
          view: obj[Constants.permission.BADGE_VIEW],
          add: obj[Constants.permission.BADGE_ADD],
          edit: obj[Constants.permission.BADGE_EDIT],
          delete: obj[Constants.permission.BADGE_DELETE],
          api_view_route: ApiRoutes.badgesView,
          api_edit_route: ApiRoutes.badgesEdit,
          api_add_route: ApiRoutes.badgesAdd,
          api_delete_route: ApiRoutes.badgesDelete,
        },
        {
          key: Constants.bodyPartsKey,
          value: Constants.bodyParts,
          view: obj[Constants.permission.BODY_PART_VIEW],
          add: obj[Constants.permission.BODY_PART_ADD],
          edit: obj[Constants.permission.BODY_PART_EDIT],
          delete: obj[Constants.permission.BODY_PART_DELETE],
          api_view_route: ApiRoutes.bodyPartsView,
          api_edit_route: ApiRoutes.bodyPartsEdit,
          api_add_route: ApiRoutes.bodyPartsAdd,
          api_delete_route: ApiRoutes.bodyPartsDelete,
        },
        {
          key: Constants.exerciseCategoryKey,
          value: Constants.exerciseCategory,
          view: obj[Constants.permission.EXER_CATEGORY_VIEW],
          add: obj[Constants.permission.EXER_CATEGORY_ADD],
          edit: obj[Constants.permission.EXER_CATEGORY_EDIT],
          delete: obj[Constants.permission.EXER_CATEGORY_DELETE],
          api_view_route: ApiRoutes.exerCategoryView,
          api_edit_route: ApiRoutes.exerCategoryEdit,
          api_add_route: ApiRoutes.exerCategoryAdd,
          api_delete_route: ApiRoutes.exerCategoryDelete,
        },
        {
          key: Constants.challengeKey,
          value: Constants.challenge,
          view: obj[Constants.permission.CHALLENGE_VIEW],
          add: obj[Constants.permission.CHALLENGE_ADD],
          edit: obj[Constants.permission.CHALLENGE_EDIT],
          delete: obj[Constants.permission.CHALLENGE_DELETE],
          api_view_route: ApiRoutes.challengeView,
          api_edit_route: ApiRoutes.challengeEdit,
          api_add_route: ApiRoutes.challengeAdd,
          api_delete_route: ApiRoutes.challengeDelete,
        },
        {
          key: Constants.usersKey,
          value: Constants.customer,
          view: obj[Constants.permission.CUSTOMER_VIEW],
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.usersView,
        },
        {
          key: Constants.cmsKey,
          value: Constants.cms,
          view: obj[Constants.permission.CMS_VIEW],
          add: obj[Constants.permission.CMS_ADD],
          edit: obj[Constants.permission.CMS_EDIT],
          delete: obj[Constants.permission.CMS_DELETE],
          api_view_route: ApiRoutes.cmsView,
          api_edit_route: ApiRoutes.cmsEdit,
          api_add_route: ApiRoutes.cmsAdd,
          api_delete_route: ApiRoutes.cmsDelete,
        },
        {
          key: Constants.equipmentKey,
          value: Constants.equipment,
          view: obj[Constants.permission.EQUIPMENT_VIEW],
          add: obj[Constants.permission.EQUIPMENT_ADD],
          edit: obj[Constants.permission.EQUIPMENT_EDIT],
          delete: obj[Constants.permission.EQUIPMENT_DELETE],
          api_view_route: ApiRoutes.equipmentView,
          api_edit_route: ApiRoutes.equipmentEdit,
          api_add_route: ApiRoutes.equipmentAdd,
          api_delete_route: ApiRoutes.equipmentDelete,
        },
        {
          key: Constants.exerciseKey,
          value: Constants.exercise,
          view: obj[Constants.permission.EXERCISE_VIEW],
          add: obj[Constants.permission.EXERCISE_ADD],
          edit: obj[Constants.permission.EXERCISE_EDIT],
          delete: obj[Constants.permission.EXERCISE_DELETE],
          api_view_route: ApiRoutes.exerView,
          api_edit_route: ApiRoutes.exerEdit,
          api_add_route: ApiRoutes.exerAdd,
          api_delete_route: ApiRoutes.exerDelete,
        },
        {
          key: Constants.exerciseGoalKey,
          value: Constants.exerciseGoal,
          view: obj[Constants.permission.EXER_GOAL_VIEW],
          add: obj[Constants.permission.EXER_GOAL_ADD],
          edit: obj[Constants.permission.EXER_GOAL_EDIT],
          delete: obj[Constants.permission.EXER_GOAL_DELETE],
          api_view_route: ApiRoutes.exerGoalView,
          api_edit_route: ApiRoutes.exerGoalEdit,
          api_add_route: ApiRoutes.exerGoalAdd,
          api_delete_route: ApiRoutes.exerGoalDelete,
        },
        {
          key: Constants.goalKey,
          value: Constants.goals,
          view: obj[Constants.permission.GOAL_VIEW],
          add: obj[Constants.permission.GOAL_ADD],
          edit: obj[Constants.permission.GOAL_EDIT],
          delete: obj[Constants.permission.GOAL_DELETE],
          api_view_route: ApiRoutes.goalView,
          api_edit_route: ApiRoutes.goalEdit,
          api_add_route: ApiRoutes.goalAdd,
          api_delete_route: ApiRoutes.goalDelete,
        },
        {
          key: Constants.mechanicsKey,
          value: Constants.mechanics,
          view: obj[Constants.permission.MECHANICS_VIEW],
          add: obj[Constants.permission.MECHANICS_ADD],
          edit: obj[Constants.permission.MECHANICS_EDIT],
          delete: obj[Constants.permission.MECHANICS_DELETE],
          api_view_route: ApiRoutes.mechanicsView,
          api_edit_route: ApiRoutes.mechanicsEdit,
          api_add_route: ApiRoutes.mechanicsAdd,
          api_delete_route: ApiRoutes.mechanicsDelete,
        },
        {
          key: Constants.notificationKey,
          value: Constants.notification,
          view: obj[Constants.permission.NOTIFI_VIEW],
          add: obj[Constants.permission.NOTIFI_ADD],
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.notifiView,
          api_add_route: ApiRoutes.notifiAdd,
        },
        {
          key: Constants.programmeKey,
          value: Constants.programme,
          view: obj[Constants.permission.PROGRAMME_VIEW],
          add: obj[Constants.permission.PROGRAMME_ADD],
          edit: obj[Constants.permission.PROGRAMME_EDIT],
          delete: obj[Constants.permission.PROGRAMME_DELETE],
          api_view_route: ApiRoutes.programmeView,
          api_edit_route: ApiRoutes.programmeEdit,
          api_add_route: ApiRoutes.programmeAdd,
          api_delete_route: ApiRoutes.programmeDelete,
        },
        {
          key: Constants.reportsKey,
          value: Constants.reports,
          view: obj[Constants.permission.REPORT_VIEW],
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.reportView,
        },
        {
          key: Constants.downloadReportKey,
          value: Constants.downloadReport,
          view: obj[Constants.permission.DOWNLOAD_REPORT_VIEW],
          add: Constants.disabled,
          edit: Constants.disabled,
          delete: Constants.disabled,
          api_view_route: ApiRoutes.reportView,
        },
        {
          key: Constants.roleMgtKey,
          value: Constants.roleMgt,
          view: obj[Constants.permission.ROLE_MGT_VIEW],
          add: obj[Constants.permission.ROLE_MGT_ADD],
          edit: obj[Constants.permission.ROLE_MGT_EDIT],
          delete: obj[Constants.permission.ROLE_MGT_DELETE],
          api_view_route: ApiRoutes.roleMgtView,
          api_edit_route: ApiRoutes.roleMgtEdit,
          api_add_route: ApiRoutes.roleMgtAdd,
          api_delete_route: ApiRoutes.roleMgtDelete,
        },
        {
          key: Constants.tutorialKey,
          value: Constants.tutorial,
          view: obj[Constants.permission.TUTORIAL_VIEW],
          add: obj[Constants.permission.TUTORIAL_ADD],
          edit: obj[Constants.permission.TUTORIAL_EDIT],
          delete: obj[Constants.permission.TUTORIAL_DELETE],
          api_view_route: ApiRoutes.tutorialView,
          api_edit_route: ApiRoutes.tutorialEdit,
          api_add_route: ApiRoutes.tutorialAdd,
          api_delete_route: ApiRoutes.tutorialDelete,
        },
        {
          key: Constants.workoutKey,
          value: Constants.workout,
          view: obj[Constants.permission.WORKOUT_VIEW],
          add: obj[Constants.permission.WORKOUT_ADD],
          edit: obj[Constants.permission.WORKOUT_EDIT],
          delete: obj[Constants.permission.WORKOUT_DELETE],
          api_view_route: ApiRoutes.workoutView,
          api_edit_route: ApiRoutes.workoutView,
          api_add_route: ApiRoutes.workoutView,
          api_delete_route: ApiRoutes.workoutView,
        },
      ]);
    } else {
      setSequence(permissionList);
    }
  }, [permissionlist]);

  // Checkbox change
  const handleModuleTypeChange = (e, index, type) => {
    const copiedObject = JSON.parse(JSON.stringify(sequence));
    copiedObject[index][type] = e.target.checked;
    setSequence(copiedObject);
  };

  // convert to API required data format & permission API to grant access to role
  const handleGrantAccess = () => {
    const resultArray = [];
    sequence.forEach((elt) => {
      resultArray.push({
        key: `${elt.key}_${Constants.viewKey}`,
        enabled: elt.view === true,
        api_route: elt.api_view_route || ["a"],
        api_method: "GET",
      });
      resultArray.push({
        key: `${elt.key}_${Constants.editKey}`,
        enabled: elt.edit === true,
        api_route: elt.api_edit_route || ["a"],
        api_method: "PATCH",
      });
      resultArray.push({
        key: `${elt.key}_${Constants.addKey}`,
        enabled: elt.add === true,
        api_route: elt.api_add_route || ["a"],
        api_method: "POST",
      });
      resultArray.push({
        enabled: elt.delete === true,
        key: `${elt.key}_${Constants.deleteKey}`,
        api_route: elt.api_delete_route || ["a"],
        api_method: "DELETE",
      });
    });
    const data = {
      role: selectedRole?._id,
      permissions: resultArray,
    };
    // Add permission API
    dispatch(addRolePermission(data)).then((response) => {
      if (response.error) {
        dispatch({
          type: types.ERROR_ALERT_VISIBLE,
          payload: response.error,
        });
      } else {
        dispatch(fetchRolePermission(selectedRole?._id));
        dispatch({
          type: types.SUCCESS_ALERT_VISIBLE,
          payload: response.data.message,
        });
      }
    });
  };

  return (
    <div className="container-xxl">
      <div className="row">
        <div className="col-lg-3">
          <RoleList onChange={(role) => setSelectedRole(role)} />
        </div>
        <div className="col-lg-9">
          <div className="d-flex flex-bw my-2">
            <PageHeader pagetitle={Constants.roleMgt} />
            <div className="btn-hover">
              {selectedRole &&
                ((addPermission && !permissionlist?.permissions?.length > 0) ||
                  (editPermission &&
                    permissionlist?.permissions?.length > 0)) && (
                  <button
                    type="button"
                    className=" btn btn-primary"
                    onClick={() => {
                      handleGrantAccess();
                    }}
                  >
                    {Constants.grantAccess}
                  </button>
                )}
            </div>
          </div>
          <form>
            <div className="table-responsive table-h" id="style-2">
              <table className="table custom-table table-striped module-role ">
                <thead></thead>
                <tbody>
                  {sequence?.map((modules, index) => (
                    <tr key={modules?.value}>
                      <td>
                        <i
                          className="fa fa-folder-o me-1"
                          aria-hidden="true"
                        ></i>
                        {modules?.value}
                      </td>
                      {[
                        Constants.viewKey,
                        Constants.editKey,
                        Constants.addKey,
                        Constants.deleteKey,
                      ].map((elt) => (
                        <td key={elt}>
                          {modules[elt] !== Constants.disabled && (
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={modules[elt] === true}
                                  disabled={modules[elt] === Constants.disabled}
                                  onChange={(e) =>
                                    handleModuleTypeChange(e, index, elt)
                                  }
                                />
                                {modules?.key.includes(
                                  Constants.downloadReportKey
                                )
                                  ? Constants.allow
                                  : elt?.charAt(0)?.toUpperCase() +
                                    elt?.slice(1)}
                              </label>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageRoles;
