/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { frequentUsersList } from "../../Redux/Actions";
import { Constants } from "../Common/Constants";
import PageHeader from "../Common/PageHeader";
import TableList from "../Common/UiModel/TableList";
import ReactPagination from "../Common/Fields/ReactPagination";
import titleCase from "../Common/Functions/titleCase";
import { Link } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import ReactSelect from "../Common/Fields/ReactSelect";

const UsersList = () => {
  const dispatch = useDispatch();
  const { frequentuserlist } = useSelector((state) => state.Reports);
  const [usersData, setUsersData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedFreq, setSelectedFreq] = useState(Constants.freqOptions[0]);
  const [search, setSearch] = useState("");
  const [selectedGender, setSelectedGender] = useState(
    Constants.genderOptions[0]
  );

  useEffect(() => {
    // Users list API
    getUsersData();
  }, []);

  useEffect(() => {
    // Set user list data
    setUsersData(frequentuserlist?.items);
  }, [frequentuserlist]);

  // Columns for User list
  const columns = [
    {
      name: Constants.id,
      width: "6%",
      cell: (_row, i) =>
        pageIndex * Constants.limitCount - Constants.limitCount + i + 1,
    },
    {
      name: Constants.email,
      selector: (row) => (
        <Link to={`${RoutesUrl.users}/${row?.userData?._id}`}>
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.userData?.email}
          >
            {row?.userData?.email}
          </span>
        </Link>
      ),
    },
    {
      name: Constants.name,
      selector: (row) => row?.userData?.name || Constants.na,
    },
    {
      name: Constants.gender,
      selector: (row) => titleCase(row?.userData?.gender),
    },
    {
      name: Constants.workoutCount,
      selector: (row) => row?.countWorkout,
    },
    {
      name: Constants.goals,
      selector: (row) => row?.userData?.goals?.type || Constants.na,
    },
  ];

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // De-bounce function for search with delay of 500 seconds
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getUsersData(search);
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [search]);

  const getUsersData = (search) => {
    // User list API based on all filters
    const data = {
      ascending: selectedFreq.value,
      gender: selectedGender.value,
      search,
    };
    dispatch(frequentUsersList(data));
  };

  // Pagination fuction
  const handleOffset = (newOff) => {
    const data = {
      skip: newOff,
      limit: Constants.limitCount,
      ascending: selectedFreq.value,
      gender: selectedGender.value,
      search,
    };
    dispatch(frequentUsersList(data));
  };

  const handleFreq = (selected) => {
    setSelectedFreq(selected);
    setPageIndex(1);
    const data = {
      ascending: selected.value,
      gender: selectedGender.value,
      search,
    };
    dispatch(frequentUsersList(data));
  };

  const handleGender = (selected) => {
    setSelectedGender(selected);
    setPageIndex(1);
    const data = {
      ascending: selectedFreq.value,
      gender: selected.value,
      search,
    };
    dispatch(frequentUsersList(data));
  };

  const onResetFilter = () => {
    setSelectedGender(Constants.genderOptions?.[0]);
    setSelectedFreq(Constants.freqOptions?.[0]);
    setSearch("");
    setPageIndex(1);
    dispatch(frequentUsersList());
  };

  return (
    <div className="container-xxl">
      <div className="mt-3">
        <PageHeader pagetitle={Constants.frequentUsers} />
      </div>
      <div className="row mb-4 align-items-end position-relative justify-content-end">
        <div className="col-md-2">
          <ReactSelect
            label={`${Constants.gender}`}
            required={false}
            options={Constants.genderOptions}
            onChange={(selected) => handleGender(selected)}
            value={selectedGender}
          />
        </div>
        <div className="col-md-2">
          <ReactSelect
            label={`${Constants.frequency}`}
            required={false}
            options={Constants.freqOptions}
            onChange={(selected) => handleFreq(selected)}
            value={selectedFreq}
          />
        </div>
        <div className="col-md-2">
          <label className="form-label">{Constants.search}</label>
          <input
            className="px-2 filter-sec-trans"
            type="search"
            value={search}
            placeholder={Constants.searchByEmail}
            onChange={(event) => {
              setSearch(event.target.value);
              setPageIndex(1);
              if (event.target.value === "") {
                setSearch("");
                getUsersData("");
              }
            }}
          ></input>
        </div>
        <div className="col-md-1 reset-btn">
          <button
            className="btn btn-primary text-uppercase px-4  cross-reset p-right fw-bold"
            onClick={() => onResetFilter()}
          >
            {Constants.reset}
          </button>
        </div>
      </div>
      <TableList
        columns={columns}
        data={usersData}
        defaultSortFieldId
        highlightOnHover
      />
      {frequentuserlist?.totalCount > Constants.limitCount && (
        <ReactPagination
          itemsPerPage={Constants.limitCount}
          total={frequentuserlist?.totalCount}
          page={(target) => setPageIndex(target + 1)}
          newOffset={handleOffset}
          pageIndex={pageIndex - 1}
        />
      )}
    </div>
  );
};

export default UsersList;
