import { types } from "../../../Redux/Actions/types";

const responseMethod = (response, dispatch, list, data) => {
  if (response.error) {
    dispatch({
      type: types.ERROR_ALERT_VISIBLE,
      payload: response.error,
    });
  } else {
    dispatch({
      type: types.SUCCESS_ALERT_VISIBLE,
      payload: response?.data?.message,
    });
    dispatch(data ? list(data) : list());
  }
};

export default responseMethod;
