import {
  challenge,
  challengeAdd,
  challengeEdit,
  challengeRemove,
  detailChallenge,
  usersChallenge,
} from "../../services/challenge.service";
import { types } from "./types";

export const challengeInsert = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await challengeAdd(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const challengeList = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await challenge(data);
  if (response?.status) {
    const result = response?.data?.data;
    dispatch({ type: types.CHALLENGE_LIST, payload: result });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.CHALLENGE_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const challengeUpdate = (data) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await challengeEdit(data);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const challengeDelete = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await challengeRemove(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const challengeDetail = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await detailChallenge(id);
  if (response?.status) {
    const result = response?.data;
    dispatch({ type: types.CHALLENGE_SINGLE, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.CHALLENGE_SINGLE,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};

export const challengeUsers = (id) => async (dispatch) => {
  dispatch({ type: types.LOADER_VISIBLE });
  const response = await usersChallenge(id);
  if (response?.status) {
    const result = response.data;
    dispatch({ type: types.CHALLENGE_USER_LIST, payload: result.data });
    dispatch({ type: types.LOADER_HIDE });
    return { data: result };
  } else {
    dispatch({
      type: types.CHALLENGE_USER_LIST,
      payload: { error: response.error },
    });
    dispatch({ type: types.LOADER_HIDE });
    return { error: response.error };
  }
};
