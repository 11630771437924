import React from "react";
import { useSelector } from "react-redux";
import BarGraph from "../../Common/Graphs/BarGraph";
import { Constants } from "../../Common/Constants";

const Yearly = () => {
  const { workoutanalytics } = useSelector((store) => store.Reports);

  return (
    <div className="row mt-4">
      <div id="chart" className="col-md-4">
        <BarGraph
          data={workoutanalytics?.workoutSessionSplit}
          title={Constants.workoutSessionSplitInMins}
          format=""
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Yearly;
