import { combineReducers } from "redux";
import AlertMessage from "./AlertMessage";
import Auth from "./Auth";
import Badge from "./Badge";
import BodyGroup from "./BodyParts/BodyGroup";
import Challenge from "./Challenge";
import Cms from "./Cms";
import Dashboard from "./Dashboard";
import Exercise from "./Exercise";
import ExerciseGoal from "./ExerciseGoal";
import ExercisePosition from "./BodyParts/ExercisePosition";
import Joint from "./BodyParts/Joint";
import Muscle from "./BodyParts/Muscle";
import PrimaryJoint from "./BodyParts/PrimaryJoint";
import PrimaryMuscle from "./BodyParts/PrimaryMuscle";
import SynergistMuscle from "./BodyParts/SynergistMuscle";
import Equipment from "./Equipment";
import ExerciseCategory from "./ExerciseCategory";
import Goals from "./Goals";
import Force from "./Mechanics/Force";
import Loader from "./Loader";
import MechanicsJoint from "./Mechanics/MechanicsJoint";
import MovementPattern from "./Mechanics/MovementPattern";
import Programme from "./Programme";
import Reports from "./Reports";
import Roles from "./Roles";
import Tutorial from "./Tutorial";
import Users from "./Users";
import Workout from "./Workout";

export default combineReducers({
  AlertMessage,
  Auth,
  Badge,
  BodyGroup,
  Challenge,
  Cms,
  Dashboard,
  Exercise,
  Equipment,
  ExerciseCategory,
  ExerciseGoal,
  ExercisePosition,
  Force,
  Goals,
  Joint,
  Loader,
  MechanicsJoint,
  MovementPattern,
  Muscle,
  PrimaryJoint,
  PrimaryMuscle,
  Programme,
  Reports,
  Roles,
  SynergistMuscle,
  Tutorial,
  Users,
  Workout,
});
