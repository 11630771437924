import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userPermissions } from "../Redux/Actions/Auth";
import { Constants } from "../components/Common/Constants";

const LoaderSpinner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const roleId = localStorage.getItem(Constants.roleId);
    if (roleId !== "null" && !!roleId) {
      dispatch(userPermissions());
    }
  }, []);

  return (
    <div id="preloader">
      <div id="loader"></div>
    </div>
  );
};

export default LoaderSpinner;
