import React from "react";
import { Constants } from "../../Common/Constants";
import PageHeader from "../../Common/PageHeader";
import BadgeForm from "../BadgeForm";

const BadgeDetailPage = () => {
  return (
    <div className="mt-4 course-form card">
      <PageHeader pagetitle={Constants.detail} />
      <BadgeForm />
    </div>
  );
};

export default BadgeDetailPage;
